/*******************************************************
 * Nombre:       Hook Dialogo
 * Descripcion : Este mostrara un Componente dentro de un
 *               dialogo
 * Libreirias:   Mui.com, iconsax-react
 *
 * Ingresa:     Componente: Componente a mostrar en Dialogo
 *              Width: Tamano de Dialogo ( xs , sm, lg, md, xl )
 *              Titulo: String de titulo a mostrar en Dialogo
 *
 * Retorna:     Dialogo = Componente Visible
 *              abrir = funcion de abrir dialogo
 *              cerrar = funcion de cerra dialogo ( esta es
 *              una variable asi que se la puede usar en el
 *              componente hijo tambien )
 *
 * Autor: Luis Rosero
 * Tiempo: 2 hrs
 *******************************************************/
import {ButtonBase, Dialog, Grid, Grow, Slide, Typography, useMediaQuery} from "@mui/material";
import {useState,} from "react";
import {CloseSquare} from "iconsax-react";
import {theme} from "../../Tema";




const COLORBOTON = "#FC783E"
const COLORICONO = "#FFFFFF"
const COLORTITULO = "#25282F"


export const useDialogo = ({Componente, titulo = '', fullScreen = true, cerrarPadre = null}) => {
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))


    const abrir = (e) => {
        if (e !== null || true) {
            setId(e)
        }
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }


    const Dialogo = () => {

        return (
            <Dialog
                open={open}
                scroll={"body"}
                fullWidth maxWidth={!fullScreen ? "xs" : "xs"} fullScreen={sCell && fullScreen}
                sx={{margin: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{p: 3}}
                    spacing={2}
                    transition={{duration: 5}}
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >

                            <Grid item lg={11} sm={11} xs={10}>
                                <Typography
                                    sx={{color: COLORTITULO, fontSize: 18, fontWeight: 600}}>{titulo}</Typography>
                            </Grid>

                            <Grid item container lg={1} sm={1} xs={2} sx={{justifyContent: "flex-end"}}>
                                <ButtonBase onClick={() => cerrar()} sx={{p: 0.5, backgroundColor: COLORBOTON, borderRadius: 2}}>
                                    <CloseSquare color={COLORICONO}/>
                                </ButtonBase>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Componente cerrar={cerrar} cerrarPadre={cerrarPadre} id={id}/>
                    </Grid>


                </Grid>

            </Dialog>
        )

    }

    return {
        Dialogo,
        abrir,
        cerrar

    }
}