/**************************************************
 * Nombre:       Ebooks
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Book} from "iconsax-react";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Detalle_Ebook from "../Dialogos/Detalle_Ebook";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Ebook from "../Targetas/Tarjeta_Ebook";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";


const Ebooks = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Detalle_Ebook})
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({col: 'ebooks', orden: 'nombre', wheres: wheres})



    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >


            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={6} sm={3} xs={6}>
                        <Typography sx={{fontSize: 24, fontWeight: 800}}>eBooks</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={9} xs={6} sx={{justifyContent: "flex-end"}}>
                        <Button
                            onClick={() => abrir()}
                            startIcon={<Book variant={'Bold'}/>}
                            sx={{borderRadius: 2}}>Ingresar eBook</Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas Card={Tarjeta_Ebook} data={data} click={abrir}/>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: 2, mb: 2}}>
                <Button variant={'outlined'} sx={{color: '#FC783E'}}>Cargar Mas</Button>
            </Grid>

        </Grid>
    )
}

export default Ebooks;