/**************************************************
 * Nombre:       MenuCell
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {ButtonBase, Drawer, Grid, Typography} from "@mui/material";
import {CloseSquare, HambergerMenu} from "iconsax-react";
import logo from '../../../Recursos/logocompletoblanco.svg'
import {REDES} from "../../../Constantes";


const MenuCell = ({secciones}) => {
    const [open, setOpen] = useState(false)
    const [x, setX] = useState("Inicio")

    const cerrar = () => {
        setOpen(false)
        let hash = window.location.hash
        console.log(hash)
        setTimeout(() => {

            let hash = window.location.hash
            if (!hash) {
                return
            }

            let seccion = hash.replaceAll('#', '')
            //marcarEvento('seccion_' + seccion)
            let compo = window.document.getElementById(seccion)
            compo.scrollIntoView({
                behavior: 'smooth', // smooth scroll
                block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
            })
        }, 500)


    }

    const abrir = () => {
        setOpen(true)
    }


    return (
        <>

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <ButtonBase onClick={() => abrir()}>

                    <HambergerMenu color={"#FC783E"} size={"2rem"}/>
                </ButtonBase>


            </Grid>


            <Drawer open={open} onClose={cerrar}
                    anchor={"right"}

            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{width: 280, p: 3}}
                >


                    <Grid item lg={12} sm={12} xs={12}>
                        <ButtonBase onClick={() => cerrar()}>
                            <CloseSquare color={"#fff"} size={"2rem"}/>
                        </ButtonBase>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {secciones.map((item, index) => {
                                return (
                                    <Grid

                                        component={"div"}
                                        data-aos="fade-right"
                                        data-aos-delay={200 * index}
                                        data-aos-duration="1500"
                                        key={index}
                                        item container lg={12} sm={12} xs={12}
                                        sx={{justifyContent: "center", marginTop: 2}}>
                                        <ButtonBase
                                            href={"#" + item}

                                            sx={{
                                                //borderBottom: x === item ? 2 : 0,
                                                borderColor: "#FF6623", py: 0.5
                                            }}>
                                            <Typography
                                                //onClick={() => setX(item)}
                                                sx={{
                                                    color: x === item ? "#fff" : "#ffffff80",
                                                    fontFamily: "Montserrat",
                                                    fontSize: 20,
                                                    fontWeight: 700,
                                                    "&:hover": {color: "#fff"},

                                                }}>{item}</Typography>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>


                    <Grid item container lg={11} sm={12} xs={8} sx={{justifyContent: "center", marginTop: 8}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            {REDES.map((item, index) => {
                                return (
                                    <Grid key={index} item container lg={4} sm={4} xs={4} sx={{justifyContent: "center"}}>
                                        <ButtonBase
                                            // onClick={() => irRed()}
                                        >
                                            <img src={item.img} width={34} height={'auto'} alt={item.nombre}/>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}


                        </Grid>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 30}}>


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: "center", marginTop: 5}}>
                            <img src={logo} width={"90%"} height={"auto"}
                                 alt={"Logo international logistics MD"} title={"Logo international logistics MD"}/>
                        </Grid>


                    </Grid>

                </Grid>
            </Drawer>


        </>
    )
}

export default MenuCell;
