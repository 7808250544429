import {createContext, useEffect, useState} from 'react';
import './App.css';
import {Grid} from "@mui/material";
import Dashboard from "./Dashboard/Dashboard";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import Pagina from "./Pagina/Pagina";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {iniciarAnaliticas} from "./Servicios/Analiticas/iniciarAnaliticas";

export const usuarioContext = createContext();


function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})
    const [boton, setBoton] = useState(false)
    const [ingles, setIngles] = useState(false)

    const valoresProvider = {
        usuario,
        ingles,
        setIngles,
        boton,
        setBoton
    }

    const {Provider} = usuarioContext;


    useEffect(() => {

        iniciarAnaliticas()

        if (window.swUpdateReady) {
            window.swUpdateReady = false;
            window.stop();
            window.location.reload();
        }
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">

            <Provider value={valoresProvider}>
                {usuario ?

                    <Dashboard/>
                    :
                    <Router>
                        <Pagina/>
                    </Router>
                }

            </Provider>

        </Grid>
    );
}

export default App;
