import {PAISES} from "../../Constantes";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {enviarMail} from "../../Modulo_MailRelay/enviarMail";
import {emailcontruyendome} from "../../Modulo_MailRelay/Recursos/emailcontruyendome";

export const guardarPotencial = (entidad, item, envia = true) => {


    let obj = entidad;
    obj.ebook = item.nombre
    obj.fecha = new Date().getTime()
    obj.estado = 'nuevo'
    obj.valor = item.valor
    obj.pago = 'sin verificar'

    if (entidad.pais) {

        let paisCompleto = PAISES.find((obj) => {
            return obj.name_es === entidad.pais
        })

        obj.indicativo = paisCompleto.dial_code
    }

    return new Promise(resolve => {


        guardarDoc('potenciales', obj).then((dox) => {
            if (dox.res) {
                return resolve({res: true, data: dox.data})
            } else {
                return resolve({res: false, data: dox})
            }


        })

    })


}