/**************************************************
 * Nombre:       ListaLibros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect, useContext} from 'react';
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import libro from "../../../Recursos/librocatalogo.png";
import {obtenerColeccionQuery} from "../../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../../fire";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {URLPAGINA} from "../../../Constantes";
import {usuarioContext} from "../../../App";


const ListaLibros = ({categoria = 'Todos'}) => {
    const [ebooks, setEbooks] = useState([])
    const cData = useContext(usuarioContext)

    useEffect(() => {

        let col = collection(fire, 'ebooks')
        let q = query(col)
        if (categoria !== 'Todos') {
            q = query(col, where('categoria', '==', categoria))
        }

        if (cData.ingles) {
            if (categoria !== 'All') {
                q = query(col, where('categoriaEng', '==', categoria))
            } else {
                q = query(col, where('ingles', '==', true))
            }

        }

        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res) {
                setEbooks(dox.data)
            }
        })

    }, [categoria])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={8}

        >

            {ebooks.map((item, index) => {
                return (
                    <Grid key={index} item container lg={4} sm={6} xs={12} sx={{justifyContent: "center"}}>
                        <ButtonBase
                            onClick={() => {
                                window.open(URLPAGINA + item.id)
                                window.focus()
                            }}
                            sx={{width: '100%', borderRadius: 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{
                                    backgroundColor: "#F8F8F8",
                                    borderRadius: 2,
                                    p: 4,
                                    transition: 'all .3s ease-in-out',
                                    '&:hover': {boxShadow: 4}
                                }}
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 4}}>
                                    <img src={item.img} width={"75%"} height={"auto"} alt={"libro"} title={"libro"}
                                         style={{boxShadow: '0px 10px 30px #00000050'}}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 4}}>
                                    <Typography sx={{fontSize: 18, fontWeight: 600, textAlign: "center"}}>
                                        {item.nombre}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 2}}>
                                    <Button color={"info"} sx={{color: "#512AF8",}}>{formatoMoneda(item.valor)}</Button>
                                </Grid>

                            </Grid>
                        </ButtonBase>

                    </Grid>
                )
            })}

        </Grid>
    )
}

export default ListaLibros;