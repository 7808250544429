/**************************************************
 * Nombre:       Formulario_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Book, Category2, Flag, Link, Lovely, Message, Message2, Money, User} from "iconsax-react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";


const Formulario_Red = (props) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
            >


                <Grid item lg={3} sm={12} xs={12}/>

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.ImagenDimencion dato={"img"} carpeta={"portadas"} y={1} x={1}
                                              funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item lg={3} sm={12} xs={12}/>

                <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 2}}>
                    <FormBase.InputIcon Icono={Category2} label={'Nombre Red'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}/>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={User} label={'Usuario'} dato={'usuario'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Link} label={'Link'} dato={'link'}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Red;