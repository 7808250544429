import {createTheme} from '@mui/material/styles';


export const theme = createTheme({
    palette: {
        primary: {
            main: "#FC783E",
        },
        secondary: {
            main: "#00B3DE",
        },
        info: {
            main: "#FFF",
        },
        success: {
            main: "#9E9DA2"
        }

    },
    typography: {
        fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: "filled",
                size: "small",

            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 10,
                }
            }
        },
        MuiInputLabel:{
            styleOverrides:{
                root:{
                    color: "#B2B2B2",
                    fontSize: 16,
                    fontWeight: 600
                }
            }
        },
        MuiFormLabel: {
            styleOverrides:{
                root:{
                    paddingLeft: 10
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 6
                }
            }
        },
        MuiButtonBase: {
            variants: [
                {
                    props: {variant: 'efecto'},
                    style: {
                        transition: 'all .1s ease-in-out',
                        borderRadius: 10,
                        '&: hover': {
                            marginTop: -6,
                            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",

                        }
                    },
                },


            ],

        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 10
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    fontWeight: 800,
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 6,
                    paddingBottom: 6,
                    fontSize: 16,
                    textTransform: "none",
                    color: "#fff"
                },

            }
        },
        MuiTab:{
          styleOverrides: {
              root: {
                  fontWeight: 800
              }
          }
        },
        MuiDrawer: {
            styleOverrides:{                
                paper: {
                    backgroundColor: "#2A2536"
                }
            }
        }

    }


})
