/**************************************************
 * Nombre:       Detalle_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Ebook from "../Formularios/Formulario_Ebook";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Red from "../Formularios/Formulario_Red";
import {theme} from "../../Tema";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {URLPAGINA} from "../../Constantes";
import Formulario_Comprador from "../Formularios/Formulario_Comprador";


const Detalle_Comprador = ({cerrar, id}) => {
    const {Formulario, props, entidad, limpiarEntidad, setEntidad} = useFormulario({
        Form: Formulario_Comprador,
        nombre: 'comprador',
    })
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [cargando, setCargando] = useState(false)

    const guardar = () => {


        setCargando(true)

        guardarDoc('potenciales', entidad).then((dox) => {
            if (dox.res) {
                alert('Cambios guardados con exito')
                limpiarEntidad()
                cerrar()
            } else {
                alert('Ups, algo inesperado paso, por favor intentalo mas tarde')
            }
            setCargando(false)
        })
    }


    const borrar = () => {

        borrarDoc('potenciales', entidad.id).then((dox) => {
            if (dox.res) {
                alert('Elemento borrado')
                limpiarEntidad()
                cerrar()
            }
        })

    }

    useEffect(() => {
        if (id) {
            setEntidad(id)
        }
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Formulario {...props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: sCell ? 6 : 3, mb: 1}}>
                <LoadingButton
                    onClick={() => guardar()}
                    variant={'contained'}
                    loading={cargando}>
                    Guardar
                </LoadingButton>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                {entidad.id && entidad.id !== '1689180530586OKS' &&
                <Button
                    onClick={() => borrar()}
                    color={'success'} size={'small'} variant={'text'} sx={{color: "#00000050"}}>Borrar</Button>
                }
            </Grid>


        </Grid>
    )
}

export default Detalle_Comprador;