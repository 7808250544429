import {urlMail} from "./Contantes";
import axios from 'axios'

export const enviarMail = ({correo, nombre, tema, html}) => {


    return new Promise(resolve => {
        const data = JSON.stringify({
            email: correo,
            nombre: nombre,
            tema: tema,
            emailhtml: html
        });


        let config = {
            method: 'post',
            url: urlMail,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                return resolve({res: true, data: response.data})
            })
            .catch(function (error) {
                return resolve({res: false, data: error.message})
            });
    })

}