/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState, useContext} from 'react';
import {ButtonBase, FormControlLabel, Grid, Switch, Typography, useMediaQuery} from "@mui/material";
import firma from "../../Recursos/firma.svg";
import {theme} from "../../Tema";
import logo from "../../Recursos/logolinea.svg";
import MenuCell from "./Componentes/MenuCell";
import BarraMenus from "./Componentes/BarraMenus";
import {styled} from '@mui/material/styles';
import eeuu from '../../Recursos/eeuu.png'
import mexico from '../../Recursos/mexico.png'
import {usuarioContext} from "../../App";
import {seccionesEng, seccionesEsp} from "../../Textos/TextosBarra";


const Barra = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const cData = useContext(usuarioContext)

    const MaterialUISwitch = styled(Switch)(({theme}) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1.3,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${eeuu})`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#fff',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: '#fff',
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${mexico})`
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#fff',
            borderRadius: 20 / 2,
        },
    }));

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: "center", backgroundColor: "#2A2536", p: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{maxWidth: '1400px', px: 3}}
                >

                    <Grid item lg={4} sm={3} xs={0}>

                    </Grid>

                    <Grid item container lg={4} sm={6} xs={8} sx={{justifyContent: sCell ? 'flex-start' : "center"}}>
                        <img src={firma} alt={"Firma de Jenny Lesmes"} width={sCell ? 100 : 150} height={"auto"}/>
                    </Grid>

                    <Grid item container lg={4} sm={3} xs={4} sx={{justifyContent: "flex-end"}}>
                        {cData.boton &&
                            <FormControlLabel
                                sx={{color: '#fff', width: masSM ? 120 : 90}}
                                control={<MaterialUISwitch checked={cData.ingles}
                                                           onChange={() => cData.setIngles(!cData.ingles)}/>}
                                label={cData.ingles ? "English" : "Español"}
                            />}
                    </Grid>

                </Grid>


            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2,}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{maxWidth: "1400px", px: 3}}
                >

                    <Grid item lg={4} sm={6} xs={8}>
                        <img src={logo} alt={"Logo construyendome"} width={sCell ? 200 : 250} height={"auto"}/>
                    </Grid>

                    <Grid item container lg={8} sm={6} xs={4} sx={{justifyContent: "flex-end"}}>

                        {masSM ?
                            <BarraMenus secciones={cData.ingles ? seccionesEng : seccionesEsp}/> :
                            <MenuCell secciones={cData.ingles ? seccionesEng : seccionesEsp}/>
                        }
                    </Grid>


                </Grid>
            </Grid>

        </Grid>
    )
}

export default Barra;


