/**************************************************
 * Nombre:       DRecibo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";
import Pdf_Recibo from "../../Modulo_Pdf/Componentes/Pdf_Recibo";
import {PDFDownloadLink} from '@react-pdf/renderer'
import {enviarMail} from "../../Modulo_MailRelay/enviarMail";
import {emailcontruyendome} from "../../Modulo_MailRelay/Recursos/emailcontruyendome";
import logo from "../../Recursos/logo_redondo.png";
import {irUrl} from "../../Utilidades/irUrl";


const Dialogo_Recibo = ({item}) => {
    const [datos, setDatos] = useState(undefined)

    const getMensaje = (num) => {
        switch (num) {
            case 1 :
                return "Una copia del eBook ha sido enviada a tu correo, por favor revisa tu bandeja de entrada y de promociones."
                break
            case 2 :
                return "Ups! rechazaron tu tarjeta, por favor revisa tu saldo"
                break
            case 3 :
                return "Tu transaccion esta pendiente a autorizarse, descarga el resibo y comunicate con nuestros vendedores"
                break
            case 4 :
                return "Ups! no se realizo la transaccion, comunicate con tu banco e intentalo nuevamente"
                break
            default :
                return "Transaccion Fallida, intental nuevamente"

        }
    }

    const getMensajeDos = (num) => {
        switch (num) {
            case 1 :
                return "Si tienes alguna dificultad para descargarlo contactate con nosotros"
                break
            case 2 :
                return "Ups! rechazaron tu tarjeta, por favor revisa tu saldo"
                break
            case 3 :
                return "Tu transaccion esta pendiente a autorizarse, descarga el resibo y comunicate con nuestros vendedores"
                break
            case 4 :
                return "Ups! no se realizo la transaccion, comunicate con tu banco e intentalo nuevamente"
                break
            default :
                return "Transaccion Fallida, intental nuevamente"

        }
    }


    const guardarCambios = (compra) => {
        let cambios = {
            banco: item.x_bank_name,
            pago: item.x_transaction_state,
            metodo: item.x_type_payment,
            id_transaccion: item.x_transaction_id,
            aprobacion: item.x_approval_code,
            referencia: item.x_ref_payco,
            fechaPago: item.x_fecha_transaccion

        }
        console.log('cambios', cambios)

        actualizarDoc("potenciales", compra.id, cambios).then((act) => {
            if (act.res) {
                setDatos({...compra, ...cambios})
            } else {
                alert("Error al cargar recibo, copie el id de su compra: \n" + item.ref_payco + "\nComuniquese con SD Tecnology.")
            }
        })
    }


    useEffect(() => {
        if (item && item.x_extra1) {
            obtenerDoc("potenciales", item.x_extra1).then((dox) => {
                if (dox.res) {
                    let compra = dox.data;
                    console.log(item)
                    if (compra.pago !== item.x_transaction_state) {

                        if (item.x_transaction_state === 'Aceptada') {
                            console.log('enviando mail')
                            enviarMail({
                                correo: compra.correo,
                                nombre: compra.nombre,
                                tema: "Tu eBook " + compra.ebook,
                                html: emailcontruyendome({nombre: compra.ebook, link: item.x_extra2}),
                            }).then((doxemail) => {
                                if (doxemail.res) {
                                    guardarCambios(compra)
                                } else {
                                    alert('Ups, no pudimo enviar el eBook a tu correo, por favor comunicate con nosotros')
                                }

                            }).catch((err) => {
                                alert('Ups, no pudimo enviar el eBook a tu correo, por favor comunicate con nosotros')
                            })
                        } else {
                            console.log('no se envia correo')
                        }


                    } else {
                        guardarCambios(compra)
                    }
                }
            })
        }
    }, [item])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: 4}}
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Typography sx={{fontSize: 18, fontWeight: 400}}>El estado de tu compra es</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Typography sx={{fontSize: 22, fontWeight: 800}}>{item.x_transaction_state}</Typography>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: 2}}>
                <img src={logo} width={150} height={'auto'} alt={'logo construyendome'} title={'logo'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <Typography sx={{fontWeight: 900, fontSize: 40, textAlign: 'center', lineHeight: 1}}>Gracias por tu
                    apoyo</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                {item && item.x_cod_transaction_state === 1 ?
                    <Typography sx={{fontWeight: 500, fontSize: 18, textAlign: 'center', lineHeight: 1}}>Por favor
                        descarga
                        el eBook haciendo click en el boton descargar, si tienes alguna dificultad para hacerlo no dudes
                        en
                        escribirnos al correo info@construyendome.com</Typography>

                    :

                    <Typography sx={{fontWeight: 500, fontSize: 18, textAlign: 'center', lineHeight: 1}}>
                        Tu transaccion no fue ACEPTADA, si tu transaccion esta PENDIENTE de verificacion nos
                        comunicaremos contigo para hacerte llegar tu eBook, o comunicate al correo
                        info@construyendome.com
                    </Typography>


                }
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4, marginBottom: 2}}>
                {item && item.x_cod_transaction_state === 1 &&
                <Button
                    onClick={() => irUrl(item.x_extra2)}
                >Descargar</Button>
                }
            </Grid>


        </Grid>
    )
}

export default Dialogo_Recibo;