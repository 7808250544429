export const emailcontruyendome = ({nombre, link}) => {

  let it = "<html> " +
      "  <head> " +
      "    <title> " +
      "    </title> " +
      "    <!--[if !mso]><!--> " +
      "    <meta http-equiv= 'X-UA-Compatible ' content= 'IE=edge '> " +
      "    <!--<![endif]--> " +
      "      <meta http-equiv= 'Content-Type ' content= 'text/html; charset=UTF-8 '> " +
      "      <meta name= 'viewport ' content= 'width=device-width, initial-scale=1 '> " +
      "      <style type= 'text/css '> " +
      "        #outlook a { padding:0; } " +
      "        body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; } " +
      "        table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; } " +
      "        img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; } " +
      "        p { display:block;margin:13px 0; } " +
      "      </style> " +
      "      <!--[if mso]> " +
      "        <noscript> " +
      "          <xml> " +
      "            <o:OfficeDocumentSettings> " +
      "              <o:AllowPNG/> " +
      "                <o:PixelsPerInch>96</o:PixelsPerInch> " +
      "              </o:OfficeDocumentSettings> " +
      "            </xml> " +
      "          </noscript> " +
      "      <![endif]--> " +
      "      <!--[if lte mso 11]> " +
      "        <style type= 'text/css '> " +
      "          .mj-outlook-group-fix { width:100% !important; } " +
      "        </style> " +
      "      <![endif]--> " +
      "      <!--[if !mso]><!--> " +
      "        <link href= 'https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700 ' rel= 'stylesheet ' type= 'text/css '> " +
      "        <style type= 'text/css '> " +
      "          @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700); " +
      "        </style> " +
      "      <!--<![endif]--> " +
      "        <style type= 'text/css '> " +
      "          @media only screen and (min-width:480px) { " +
      "            .mj-column-per-100 { width:100% !important; max-width: 100%; } " +
      "          } " +
      "        </style> " +
      "        <style media= 'screen and (min-width:480px) '> " +
      "          .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; } " +
      "        </style> " +
      "        <style type= 'text/css '> " +
      "          @media only screen and (max-width:480px) { " +
      "            table.mj-full-width-mobile { width: 100% !important; } " +
      "            td.mj-full-width-mobile { width: auto !important; } " +
      "          } " +
      "        </style> " +
      "        <style type= 'text/css '></style> " +
      "      </head> " +
      "      <body style= 'word-spacing:normal;background-color:#ffffff; '> " +
      "        <div style= 'background-color:#ffffff; '> " +
      "          <table align= 'center ' class= 'ed-section ed-section-header ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'background:#ffffff;background-color:#ffffff;width:100%; '> " +
      "            <tbody> " +
      "              <tr> " +
      "                <td> " +
      "                  <!--[if mso | IE]> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-section-outlook ed-section-header-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' bgcolor= '#ffffff ' > " +
      "                      <tr> " +
      "                        <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                  <div style= 'margin:0px auto;max-width:700px; '> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                      <tbody> " +
      "                        <tr> " +
      "                          <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-top:0px;text-align:center; '> " +
      "                            <!--[if mso | IE]> " +
      "                              <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                <tr> " +
      "                                  <td class= 'ed-block-outlook ed-block-6-outlook ' width= '700px ' > " +
      "                                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-block-outlook ed-block-6-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' > " +
      "                                      <tr> " +
      "                                        <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                            <div class= 'ed-block ed-block-6 ' style= 'margin:0px auto;max-width:700px; '> " +
      "                              <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                                <tbody> " +
      "                                  <tr> " +
      "                                    <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-top:20px;text-align:center; '> " +
      "                                      <!--[if mso | IE]> " +
      "                                        <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                          <tr> " +
      "                                            <td class= ' ' style= 'vertical-align:top;width:700px; ' ><![endif]--> " +
      "                                      <div class= 'mj-column-per-100 mj-outlook-group-fix ' style= 'font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%; '> " +
      "                                        <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'vertical-align:top; ' width= '100% '> " +
      "                                          <tbody> " +
      "                                            <tr> " +
      "                                              <td align= 'center' style= 'font-size:0px;padding:0 10px;word-break:break-word; '> " +
      "                                                <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'border-collapse:collapse;border-spacing:0px; '> " +
      "                                                  <tbody> " +
      "                                                    <tr> " +
      "                                                      <td align='center' style= 'width:300px; '> " +
      "                                                        <img alt= 'logo_redondo.png ' height= 'auto ' src='https://construyendome.com/recursos/logo_redondo.png' style= 'border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px; ' width= '300 '> " +
      "                                                      </td> " +
      "                                                    </tr> " +
      "                                                  </tbody> " +
      "                                                </table> " +
      "                                              </td> " +
      "                                            </tr> " +
      "                                          </tbody> " +
      "                                        </table> " +
      "                                      </div> " +
      "                                      <!--[if mso | IE]></td> " +
      "                                    </tr> " +
      "                                  </table> " +
      "                                      <![endif]--> " +
      "                                    </td> " +
      "                                  </tr> " +
      "                                </tbody> " +
      "                              </table> " +
      "                            </div> " +
      "                            <!--[if mso | IE]></td> " +
      "                          </tr> " +
      "                        </table> " +
      "                      </td> " +
      "                    </tr> " +
      "                    <tr> " +
      "                      <td class= 'ed-block-outlook ed-block-1e4fdc79-5731-47dd-8fe8-d560b776995a-outlook ' width= '700px ' > " +
      "                        <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-block-outlook ed-block-1e4fdc79-5731-47dd-8fe8-d560b776995a-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' > " +
      "                          <tr> " +
      "                            <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                            <div class= 'ed-block ed-block-1e4fdc79-5731-47dd-8fe8-d560b776995a ' style= 'margin:0px auto;max-width:700px; '> " +
      "                              <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                                <tbody> " +
      "                                  <tr> " +
      "                                    <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-top:20px;text-align:center; '> " +
      "                                      <!--[if mso | IE]> " +
      "                                        <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                          <tr> " +
      "                                            <td class= ' ' style= 'vertical-align:top;width:700px; ' ><![endif]--> " +
      "                                      <div class= 'mj-column-per-100 mj-outlook-group-fix ' style= 'font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%; '> " +
      "                                        <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'vertical-align:top; ' width= '100% '> " +
      "                                          <tbody> " +
      "                                            <tr> " +
      "                                              <td align= 'center ' style= 'font-size:0px;padding:0 10px;word-break:break-word; '> " +
      "                                                <p style= 'border-top:solid 5px #eaeaea;font-size:1px;margin:0px auto;width:100%; '> " +
      "                                                </p> " +
      "                                                <!--[if mso | IE]> " +
      "                                                  <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' style= 'border-top:solid 5px #eaeaea;font-size:1px;margin:0px auto;width:680px; ' role= 'presentation ' width= '680px ' > " +
      "                                                    <tr> " +
      "                                                      <td style= 'height:0;line-height:0; '> &nbsp; " +
      "                                                      </td> " +
      "                                                    </tr> " +
      "                                                  </table> " +
      "                                                <![endif]--> " +
      "                                              </td> " +
      "                                            </tr> " +
      "                                          </tbody> " +
      "                                        </table> " +
      "                                      </div> " +
      "                                      <!--[if mso | IE]></td> " +
      "                                    </tr> " +
      "                                  </table> " +
      "                                      <![endif]--> " +
      "                                    </td> " +
      "                                  </tr> " +
      "                                </tbody> " +
      "                              </table> " +
      "                            </div> " +
      "                            <!--[if mso | IE]></td> " +
      "                          </tr> " +
      "                        </table> " +
      "                      </td> " +
      "                    </tr> " +
      "                    <tr> " +
      "                      <td class= 'ed-block-outlook ed-block-f4837b3f-8045-4133-9565-77f9abeb59a2-outlook ' width= '700px ' > " +
      "                        <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-block-outlook ed-block-f4837b3f-8045-4133-9565-77f9abeb59a2-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' > " +
      "                          <tr> " +
      "                            <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                            <div class= 'ed-block ed-block-f4837b3f-8045-4133-9565-77f9abeb59a2 ' style= 'margin:0px auto;max-width:700px; '> " +
      "                              <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                                <tbody> " +
      "                                  <tr> " +
      "                                    <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-top:20px;text-align:center; '> " +
      "                                      <!--[if mso | IE]> " +
      "                                        <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                          <tr> " +
      "                                            <td class= ' ' style= 'vertical-align:top;width:700px; ' ><![endif]--> " +
      "                                      <div class= 'mj-column-per-100 mj-outlook-group-fix ' style= 'font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%; '> " +
      "                                        <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'vertical-align:top; ' width= '100% '> " +
      "                                          <tbody> " +
      "                                            <tr> " +
      "                                              <td align= 'center ' class= 'ed-text-container ed-column-number-0 ' style= 'color: #111111; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; text-align: center; font-size: 0px; padding: 0 10px; word-break: break-word; '> " +
      "                                                <div style= 'font-family:Helvetica;font-size:16px;line-height:normal;text-align:center;color:#111111; '> " +
      "                                                  <p style= 'margin: 0 0 10px; '><span style= 'font-size:18px '><span style= 'font-family:Verdana,Geneva,sans-serif '>Se trata de un proyecto que tiene como objetivo edificar el conocimiento de tu verdadero yo. Este viaje se emprenderá a través de la observación interior y de la búsqueda de información en tu propio ser. El análisis y comprensión de esta información te guiará hacia un camino de liberación, armonía y bienesta</span></span></p> " +
      "                                                </div> " +
      "                                              </td> " +
      "                                            </tr> " +
      "                                          </tbody> " +
      "                                        </table> " +
      "                                      </div> " +
      "                                      <!--[if mso | IE]></td> " +
      "                                    </tr> " +
      "                                  </table> " +
      "                                      <![endif]--> " +
      "                                    </td> " +
      "                                  </tr> " +
      "                                </tbody> " +
      "                              </table> " +
      "                            </div> " +
      "                            <!--[if mso | IE]></td> " +
      "                          </tr> " +
      "                        </table> " +
      "                      </td> " +
      "                    </tr> " +
      "                    <tr> " +
      "                      <td class= 'ed-block-outlook ed-block-f74c5f37-1bf6-43cd-9203-317282caf2c1-outlook ' width= '700px ' > " +
      "                        <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-block-outlook ed-block-f74c5f37-1bf6-43cd-9203-317282caf2c1-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' > " +
      "                          <tr> " +
      "                            <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                            <div class= 'ed-block ed-block-f74c5f37-1bf6-43cd-9203-317282caf2c1 ' style= 'margin:0px auto;max-width:700px; '> " +
      "                              <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                                <tbody> " +
      "                                  <tr> " +
      "                                    <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-top:20px;text-align:center; '> " +
      "                                      <!--[if mso | IE]> " +
      "                                        <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                          <tr> " +
      "                                            <td class= ' ' style= 'vertical-align:top;width:700px; ' ><![endif]--> " +
      "                                      <div class= 'mj-column-per-100 mj-outlook-group-fix ' style= 'font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%; '> " +
      "                                        <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'vertical-align:top; ' width= '100% '> " +
      "                                          <tbody> " +
      "                                            <tr> " +
      "                                              <td align= 'center ' class= 'ed-text-container ed-column-number-0 ' style= 'color: #111111; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; text-align: center; font-size: 0px; padding: 0 10px; word-break: break-word; '> " +
      "                                                <div style= 'font-family:Helvetica;font-size:16px;line-height:normal;text-align:center;color:#111111; '> " +
      `<p style= 'margin: 0 0 10px; '><span style= 'color:#3498db '><strong><span style= 'font-family:Verdana,Geneva,sans-serif '><span style= 'font-size:28px '>${nombre}</span></span></strong></span></p>` +
      "                                                </div> " +
      "                                              </td> " +
      "                                            </tr> " +
      "                                          </tbody> " +
      "                                        </table> " +
      "                                      </div> " +
      "                                      <!--[if mso | IE]></td> " +
      "                                    </tr> " +
      "                                  </table> " +
      "                                      <![endif]--> " +
      "                                    </td> " +
      "                                  </tr> " +
      "                                </tbody> " +
      "                              </table> " +
      "                            </div> " +
      "                            <!--[if mso | IE]></td> " +
      "                          </tr> " +
      "                        </table> " +
      "                      </td> " +
      "                    </tr> " +
      "                    <tr> " +
      "                      <td class= 'ed-block-outlook ed-block-7eba10eb-0f44-4052-a25a-18b2b70a7e73-outlook ' width= '700px ' > " +
      "                        <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-block-outlook ed-block-7eba10eb-0f44-4052-a25a-18b2b70a7e73-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' > " +
      "                          <tr> " +
      "                            <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                            <div class= 'ed-block ed-block-7eba10eb-0f44-4052-a25a-18b2b70a7e73 ' style= 'margin:0px auto;max-width:700px; '> " +
      "                              <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                                <tbody> " +
      "                                  <tr> " +
      "                                    <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-top:20px;text-align:center; '> " +
      "                                      <!--[if mso | IE]> " +
      "                                        <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                          <tr> " +
      "                                            <td class= ' ' style= 'vertical-align:top;width:700px; ' ><![endif]--> " +
      "                                      <div class= 'mj-column-per-100 mj-outlook-group-fix ' style= 'font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%; '> " +
      "                                        <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'vertical-align:top; ' width= '100% '> " +
      "                                          <tbody> " +
      "                                            <tr> " +
      "                                              <td align= 'center ' style= 'font-size:0px;padding:0 10px;word-break:break-word; '> " +
      "                                                <p style= 'border-top:solid 5px #eaeaea;font-size:1px;margin:0px auto;width:100%; '> " +
      "                                                </p> " +
      "                                                <!--[if mso | IE]> " +
      "                                                  <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' style= 'border-top:solid 5px #eaeaea;font-size:1px;margin:0px auto;width:680px; ' role= 'presentation ' width= '680px ' > " +
      "                                                    <tr> " +
      "                                                      <td style= 'height:0;line-height:0; '> &nbsp; " +
      "                                                      </td> " +
      "                                                    </tr> " +
      "                                                  </table> " +
      "                                                <![endif]--> " +
      "                                              </td> " +
      "                                            </tr> " +
      "                                          </tbody> " +
      "                                        </table> " +
      "                                      </div> " +
      "                                      <!--[if mso | IE]></td> " +
      "                                    </tr> " +
      "                                  </table> " +
      "                                      <![endif]--> " +
      "                                    </td> " +
      "                                  </tr> " +
      "                                </tbody> " +
      "                              </table> " +
      "                            </div> " +
      "                            <!--[if mso | IE]></td> " +
      "                          </tr> " +
      "                        </table> " +
      "                      </td> " +
      "                    </tr> " +
      "                    <tr> " +
      "                      <td class= 'ed-block-outlook ed-block-3d8138fa-d0a8-4f04-848c-f52c0bc6f921-outlook ' width= '700px ' > " +
      "                        <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-block-outlook ed-block-3d8138fa-d0a8-4f04-848c-f52c0bc6f921-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' > " +
      "                          <tr> " +
      "                            <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                            <div class= 'ed-block ed-block-3d8138fa-d0a8-4f04-848c-f52c0bc6f921 ' style= 'margin:0px auto;max-width:700px; '> " +
      "                              <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                                <tbody> " +
      "                                  <tr> " +
      "                                    <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:41px;padding-top:20px;text-align:center; '> " +
      "                                      <!--[if mso | IE]> " +
      "                                        <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                          <tr> " +
      "                                            <td class= ' ' style= 'vertical-align:top;width:700px; ' ><![endif]--> " +
      "                                      <div class= 'mj-column-per-100 mj-outlook-group-fix ' style= 'font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%; '> " +
      "                                        <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'vertical-align:top; ' width= '100% '> " +
      "                                          <tbody> " +
      "                                            <tr> " +
      "                                              <td align='center' vertical-align= 'middle ' style= 'font-size:0px;padding:0 10px;word-break:break-word; '> " +
      "                                                <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'border-collapse:separate;line-height:100%; '> " +
      "                                                  <tbody> " +
      "                                                    <tr> " +
      "                                                      <td align='center' bgcolor= '#fc783e ' role= 'presentation ' style= 'border:0px solid #fc783e;border-radius:16px;cursor:auto;mso-padding-alt:14px;text-align:center;background:#fc783e; ' valign= 'middle '> " +
      `<a href= '${link}' style= 'display:inline-block;background:#fc783e;color:#ffffff;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:20px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:20px;mso-padding-alt:0px;border-radius:14px; ' target= '_blank '> ` +
      "                                                          Descargar Aqui " +
      "                                                        </a> " +
      "                                                      </td> " +
      "                                                    </tr> " +
      "                                                  </tbody> " +
      "                                                </table> " +
      "                                              </td> " +
      "                                            </tr> " +
      "                                          </tbody> " +
      "                                        </table> " +
      "                                      </div> " +
      "                                      <!--[if mso | IE]></td> " +
      "                                    </tr> " +
      "                                  </table> " +
      "                                      <![endif]--> " +
      "                                    </td> " +
      "                                  </tr> " +
      "                                </tbody> " +
      "                              </table> " +
      "                            </div> " +
      "                            <!--[if mso | IE]></td> " +
      "                          </tr> " +
      "                        </table> " +
      "                      </td> " +
      "                    </tr> " +
      "                  </table> " +
      "                            <![endif]--> " +
      "                          </td> " +
      "                        </tr> " +
      "                      </tbody> " +
      "                    </table> " +
      "                  </div> " +
      "                  <!--[if mso | IE]></td> " +
      "                </tr> " +
      "              </table> " +
      "                  <![endif]--> " +
      "                </td> " +
      "              </tr> " +
      "            </tbody> " +
      "          </table> " +
      "          <table align= 'center ' class= 'ed-section ed-section-contentWithBackground ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'background:#808080;background-color:#808080;width:100%; '> " +
      "            <tbody> " +
      "              <tr> " +
      "                <td> " +
      "                  <!--[if mso | IE]> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-section-outlook ed-section-contentWithBackground-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' bgcolor= '#808080 ' > " +
      "                      <tr> " +
      "                        <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                  <div style= 'margin:0px auto;max-width:700px; '> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                      <tbody> " +
      "                        <tr> " +
      "                          <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-top:0px;text-align:center; '> " +
      "                            <!--[if mso | IE]> " +
      "                              <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '></table> " +
      "                            <![endif]--> " +
      "                          </td> " +
      "                        </tr> " +
      "                      </tbody> " +
      "                    </table> " +
      "                  </div> " +
      "                  <!--[if mso | IE]></td> " +
      "                </tr> " +
      "              </table> " +
      "                  <![endif]--> " +
      "                </td> " +
      "              </tr> " +
      "            </tbody> " +
      "          </table> " +
      "          <table align= 'center ' class= 'ed-section ed-section-content ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'background:#ffffff;background-color:#ffffff;width:100%; '> " +
      "            <tbody> " +
      "              <tr> " +
      "                <td> " +
      "                  <!--[if mso | IE]> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-section-outlook ed-section-content-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' bgcolor= '#ffffff ' > " +
      "                      <tr> " +
      "                        <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                  <div style= 'margin:0px auto;max-width:700px; '> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                      <tbody> " +
      "                        <tr> " +
      "                          <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-top:0px;text-align:center; '> " +
      "                            <!--[if mso | IE]> " +
      "                              <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                <tr> " +
      "                                  <td class= 'ed-block-outlook ed-block-a7f2b5c9-c943-44d5-878e-08ca9c46a2ad-outlook ' width= '700px ' > " +
      "                                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-block-outlook ed-block-a7f2b5c9-c943-44d5-878e-08ca9c46a2ad-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' > " +
      "                                      <tr> " +
      "                                        <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                            <div class= 'ed-block ed-block-a7f2b5c9-c943-44d5-878e-08ca9c46a2ad ' style= 'margin:0px auto;max-width:700px; '> " +
      "                              <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                                <tbody> " +
      "                                  <tr> " +
      "                                    <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-top:20px;text-align:center; '> " +
      "                                      <!--[if mso | IE]> " +
      "                                        <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '> " +
      "                                          <tr> " +
      "                                            <td class= ' ' style= 'vertical-align:top;width:700px; ' ><![endif]--> " +
      "                                      <div class= 'mj-column-per-100 mj-outlook-group-fix ' style= 'font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%; '> " +
      "                                        <table border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'vertical-align:top; ' width= '100% '> " +
      "                                          <tbody> " +
      "                                            <tr> " +
      "                                              <td align= 'left ' class= 'ed-text-container ed-column-number-0 ' style= 'color: #111111; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 0px; padding: 0 10px; word-break: break-word; '> " +
      "                                                <div style= 'font-family:Helvetica;font-size:16px;line-height:normal;text-align:left;color:#111111; '> " +
      "                                                  <p style= 'margin: 0 0 10px; '>dar se de b<a href= '%7B%7B%20unsubscribe_url_groups%20%7D%7D ' target= '_blank ' style= 'color: #337ab7; text-decoration: underline; '>aja</a></p> " +
      "                                                </div> " +
      "                                              </td> " +
      "                                            </tr> " +
      "                                          </tbody> " +
      "                                        </table> " +
      "                                      </div> " +
      "                                      <!--[if mso | IE]></td> " +
      "                                    </tr> " +
      "                                  </table> " +
      "                                      <![endif]--> " +
      "                                    </td> " +
      "                                  </tr> " +
      "                                </tbody> " +
      "                              </table> " +
      "                            </div> " +
      "                            <!--[if mso | IE]></td> " +
      "                          </tr> " +
      "                        </table> " +
      "                      </td> " +
      "                    </tr> " +
      "                  </table> " +
      "                            <![endif]--> " +
      "                          </td> " +
      "                        </tr> " +
      "                      </tbody> " +
      "                    </table> " +
      "                  </div> " +
      "                  <!--[if mso | IE]></td> " +
      "                </tr> " +
      "              </table> " +
      "                  <![endif]--> " +
      "                </td> " +
      "              </tr> " +
      "            </tbody> " +
      "          </table> " +
      "          <table align= 'center ' class= 'ed-section ed-section-footer ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'background:#333333;background-color:#333333;width:100%; '> " +
      "            <tbody> " +
      "              <tr> " +
      "                <td> " +
      "                  <!--[if mso | IE]> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' class= 'ed-section-outlook ed-section-footer-outlook ' role= 'presentation ' style= 'width:700px; ' width= '700 ' bgcolor= '#333333 ' > " +
      "                      <tr> " +
      "                        <td style= 'line-height:0px;font-size:0px;mso-line-height-rule:exactly; '><![endif]--> " +
      "                  <div style= 'margin:0px auto;max-width:700px; '> " +
      "                    <table align= 'center ' border= '0 ' cellpadding= '0 ' cellspacing= '0 ' role= 'presentation ' style= 'width:100%; '> " +
      "                      <tbody> " +
      "                        <tr> " +
      "                          <td style= 'direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-top:0px;text-align:center; '> " +
      "                            <!--[if mso | IE]> " +
      "                              <table role= 'presentation ' border= '0 ' cellpadding= '0 ' cellspacing= '0 '></table> " +
      "                            <![endif]--> " +
      "                          </td> " +
      "                        </tr> " +
      "                      </tbody> " +
      "                    </table> " +
      "                  </div> " +
      "                  <!--[if mso | IE]></td> " +
      "                </tr> " +
      "              </table> " +
      "                  <![endif]--> " +
      "                </td> " +
      "              </tr> " +
      "            </tbody> " +
      "          </table> " +
      "        </div> " +
      "      </body> " +
      "    </html>"

    return it
}