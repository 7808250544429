export const buscadoraEsp = 'Buscadora del desarrollo personal, espiritual y de consciencia. La mayor parte de mi vida la he dedicado al estudio y entrenamiento profesional. Viaje a España e Inglaterra con objetivos académicos elevados pero buscando respuestas en lugares equivocados'
export const buscadoraEng = 'Seeker of personal, spiritual and consciousness development. Most of my life I have dedicated to study and professional training. Journey to Spain and England with lofty academic goals but looking for answers in the wrong places'

export const enel2020Esp = 'En el 2020 mi vida dio un vuelco, estaba saturada de buscar y no encontrar lo que realmente buscaba. El estrés, la ansiedad, la tristeza y el miedo me llevaron a replantearme toda mi vida.'
export const enel2020Eng = 'In 2020 my life turned upside down, I was saturated with searching and not finding what I was really looking for. Stress, anxiety, sadness and fear led me to rethink my whole life.'

export const meDiCuentaEsp = 'Me di cuenta de que lo que buscaba no estaba afuera, sino dentro de mí misma.'
export const meDiCuentaEng = 'I realized that what I was looking for was not outside, but within myself.'

export const inspiracionEsp = 'Inspiración'
export const inspiracionEng = 'Inspiration'

export const suVerdaderaEsp = 'Su verdadera inspiración esta en ayudar a la gente a comprender las diferentes formas de alcanzar niveles mas plenos de bienestar y satisfacción.'
export const suVerdaderaEng = 'His true inspiration is in helping people understand the different ways to achieve fuller levels of well-being and satisfaction.'

export const aplicaEsp = 'Aplica en ella misma cada concepto, practica o teoría que encuentra. Esto no es un proceso finito, al contrario, el bienestar de cada ser humano depende de un proceso individualizado, y es justamente aquí donde ella desea ahondar, en el potencial de cada uno.'
export const aplicaEng = 'She applies to herself every concept, practice, or theory she encounters. This is not a finite process, on the contrary, the well-being of each human being depends on an individualized process, and it is precisely here where she wishes to delve into the potential of each one.'

export const escribemeEsp = 'Escribeme'
export const escribemeEng = 'Write me'

export const siTieneEsp = 'Si tienes el deseo de hablar con alguien, no dudes en buscarme.'
export const siTieneEng = 'If you have the desire to talk to someone, do not hesitate to look for me.'
