/**************************************************
 * Nombre:       CategorriasEbooks
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Categorias from "../Formularios/Formulario_Categorias";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";


const CategorriasEbooks = () => {
    const {Formulario, props, entidad, limpiarEntidad, setEntidad} = useFormulario({
        Form: Formulario_Categorias,
        nombre: 'categorias'
    })

    const {
        Formulario: FormularioEng,
        props: propsEng,
        entidad: entidadEng,
        limpiarEntidad: limpiarEntidadEng,
        setEntidad: setEntidadEng
    } = useFormulario({
        Form: Formulario_Categorias,
        nombre: 'categoriasEng'
    })
    const [cargando, setCargando] = useState(false)

    const guardar = () => {
        console.log(entidad)

        if (entidad && entidad.categorias) {
            setCargando(true)
            entidad.id = 'categorias'
            guardarDoc('categorias', entidad).then((dox) => {
                if (dox.res) {
                    alert('Cambios guardados con exito')
                } else {
                    alert('Ups, algo inesperado paso, intentelo nuevamente')
                }
                setCargando(false)
            })
        }

    }

    const guardarEng = () => {


        if (entidadEng && entidadEng.categorias) {
            setCargando(true)
            entidadEng.id = 'categoriasEng'
            guardarDoc('categoriasEng', entidadEng).then((dox) => {
                if (dox.res) {
                    alert('Cambios guardados con exito')
                } else {
                    alert('Ups, algo inesperado paso, intentelo nuevamente')
                }
                setCargando(false)
            })
        }

    }


    useEffect(() => {

        obtenerDoc('categorias', 'categorias').then((dox) => {
            if (dox.res) {
                setEntidad(dox.data)
            }

        })

        obtenerDoc('categoriasEng', 'categoriasEng').then((dox) => {
            if (dox.res) {
                setEntidadEng(dox.data)
            }

        })

    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
            spacing={4}
        >


            <Grid item lg={4} sm={6} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <Typography sx={{fontSize: 20, fontWeight: 800}}>Español</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                        <Formulario {...props} />
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                        <LoadingButton
                            loading={cargando}
                            onClick={() => guardar()}
                            variant={'contained'}
                        >Guardar Cambios</LoadingButton>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item lg={4} sm={6} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <Typography sx={{fontSize: 20, fontWeight: 800}}>Ingles</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                        <FormularioEng {...propsEng} />
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                        <LoadingButton
                            loading={cargando}
                            onClick={() => guardarEng()}
                            variant={'contained'}
                        >Guardar Cambios</LoadingButton>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )
}

export default CategorriasEbooks;