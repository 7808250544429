/**************************************************
 * Nombre:       Tarjeta_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Book, Lock, Message, Message2} from "iconsax-react";


const Tarjeta_Ebook = ({valores, click, difiere}) => {

    return (


        <CardBase valores={valores} sombra={0}>
            <ButtonBase onClick={() => click(valores)}
                        sx={{width: '100%', textAlign: 'left', borderRadius: 2, '&:hover': {boxShadow: 4}}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{backgroundColor: '#EBEBEB', borderRadius: 2, p: 2}}
                >


                    <Grid item lg={3} sm={6} xs={12}>
                        <CardBase.IconTexto Icono={Book} dato1={"nombre"} peso={800} />
                    </Grid>

                    <Grid item lg={8} sm={6} xs={12}>
                        <CardBase.IconTexto Icono={Message2} dato1={"slogan"} lineas={2} />
                    </Grid>

                    <Grid item container lg={1} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                        {valores.id && valores.id === '1689180530586OKS' &&
                        <Lock variant={'Bold'}/>
                        }
                    </Grid>


                </Grid>
            </ButtonBase>
        </CardBase>


    )
}

export default Tarjeta_Ebook;