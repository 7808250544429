/**************************************************
 * Nombre:       Formulario_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import {FormBase, InputArchivo} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {
    Bank,
    Book,
    Buildings,
    ClipboardText,
    Flag,
    Lovely,
    Message,
    Message2,
    Mobile,
    Money,
    Sms,
    Whatsapp
} from "iconsax-react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {subirArchivo} from "../../Servicios/Storage/subirArchivo";
import {ESTADOS} from "../../Constantes";
import {iniciarChatWhatsApp, iniciarChatWhatsAppIndicador} from "../../Utilidades/iniciarChatWhatsApp";


const Formulario_Comprador = (props) => {
    const [categorias, setCategorias] = useState([])


    useEffect(() => {

        obtenerDoc('categorias', 'categorias').then((dox) => {
            if (dox.res && dox.data.categorias) {
                setCategorias(dox.data.categorias)
            }
        })

    }, [])
    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >


                <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIconSelect Icono={Flag} label={'Estado'} dato={'estado'} opciones={ESTADOS}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>

                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Book} label={'Nombre'} dato={'nombre'} editable={false}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Lovely} label={'Como se identifica'} dato={'sexo'} editable={false}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Buildings} label={'Pais'} dato={'pais'} editable={false}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Sms} label={'Correo'} dato={'correo'} editable={false}/>
                </Grid>

                <Grid item lg={8} sm={8} xs={6} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Mobile} label={'Celular'} dato={'celular'} editable={false}/>
                </Grid>

                <Grid item container lg={4} sm={4} xs={6} sx={{justifyContent: "flex-end"}}>
                    <Button
                        onClick={() => iniciarChatWhatsAppIndicador({
                            indicativo: props.entidad.indicativo,
                            num: props.entidad.celular,
                            nombre: props.entidad.nombre
                        })}
                        color={'secondary'} endIcon={<Whatsapp/>}>Enviar</Button>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos eBook'} lg={4}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Book} label={'Nombre Ebook'} dato={'ebook'} editable={false}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6} sx={{marginTop: 0}}>
                    <FormBase.InputIconMoneda Icono={Money} label={'Cuanto pago'}  dato={'valor'} editable={false}/>
                </Grid>


                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Bank} label={'Estado de pago'} dato={'pago'}
                                              opciones={['sin verificar', 'verificado']}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={ClipboardText} label={'Notas'} dato={'notas'} lineas={10}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Comprador;