/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useContext, useState, useEffect} from 'react';
import {Grid} from "@mui/material";
import {
    ArchiveBox,
    Award, Book,
    Box, Category2,
    HambergerMenu, Health,
    Layer,
    LogoutCurve, Messages, People,
    Personalcard, Setting2, SmsTracking,
    TagUser,
    UserOctagon, UserSearch,
    UserSquare, UserTick
} from "iconsax-react";
import {salirAunth} from "../Servicios/Auth/funSalir";
import logo from '../Recursos/logocompletoblanco.svg'
import logoLargo from '../Recursos/logolinea.svg'
import fondo from '../Recursos/fondoperfil.svg'
import {DashboardBase, Barra} from "../Modulo_Dashboard/Componentes/C_Dashboard";
import {usuarioContext} from "../App";
import Ebooks from "./Secciones/Ebooks";
import CategorriasEbooks from "./Secciones/CategorriasEbooks";
import Redes from "./Secciones/Redes";
import Compradores from "./Secciones/Compradores";


const Dashboard = () => {
    const cData = useContext(usuarioContext);
    const [credenciales, setCredenciales] = useState([])


    useEffect(() => {
        if (cData && cData.usuario) {
            setCredenciales(cData.usuario.credenciales)
        }
    }, [cData])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <DashboardBase componentes={componentes} componentesID={componentesID}>

                <DashboardBase.Barra>

                    <Grid item lg={4} sm={4} xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <Grid item lg={2} sm={2} xs={4}>
                                <Barra.OpenDrawer>
                                    <HambergerMenu color={"#fff"}/>
                                </Barra.OpenDrawer>
                            </Grid>

                            <Grid item lg={10} sm={8} xs={8}>
                                <Barra.Titulo/>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Grid item container lg={8} sm={8} xs={6} sx={{justifyContent: "flex-end"}}>
                        <img src={logoLargo} width={90}/>
                    </Grid>


                </DashboardBase.Barra>

                <DashboardBase.Drawer credenciales={credenciales} logo={logo} usuario={cData.usuario} fondo={fondo}
                                      funSalir={salirAunth}/>

                <DashboardBase.Main/>

            </DashboardBase>


        </Grid>
    )
}

export default Dashboard;


const componentes = [
    {
        seccion: <Ebooks/>,
        credencial: "eBooks",
        icono: <Book variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Book variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <CategorriasEbooks/>,
        credencial: "Categorias",
        icono: <Setting2 variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Setting2 variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Redes/>,
        credencial: "Redes",
        icono: <Category2 variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Category2 variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Compradores estado={'nuevo'}/>,
        credencial: "Nuevos",
        icono: <UserSearch variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <UserSearch variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Compradores estado={'contactando'}/>,
        credencial: "Contactados",
        icono: <SmsTracking variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <SmsTracking variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Compradores estado={'seguimiento'}/>,
        credencial: "Seguimiento",
        icono: <Messages variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Messages variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Compradores estado={'comprador'}/>,
        credencial: "Compradores",
        icono: <Award variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Award variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Compradores estado={'recurrente'}/>,
        credencial: "Recurrentes",
        icono: <UserTick variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <UserTick variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Compradores estado={'archivado'}/>,
        credencial: "Archivados",
        icono: <ArchiveBox variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <ArchiveBox variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }


]


const componentesID = []