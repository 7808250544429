/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect, useContext} from 'react';
import {Button, Grid, Tab, Tabs, Typography, useMediaQuery} from "@mui/material";
import libro from '.././../Recursos/librocatalogo.png'
import {theme} from "../../Tema";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import ListaLibros from "./SubComponentes/ListaLibros";
import {usuarioContext} from "../../App";


const Catalogo = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [categorias, setCategorias] = useState(['Todos'])
    const [escogida, setEscogida] = useState('Todos')
    const cData = useContext(usuarioContext)


    useEffect(() => {

        let col = 'categorias'

        if (cData.ingles) {
            col = "categoriasEng"
            setEscogida('All')
        }

        obtenerDoc(col, col).then((dox) => {
            if (dox.res && dox.data.categorias) {
                let cats = dox.data.categorias

                if (cData.ingles) {
                    cats.unshift('All')
                } else {
                    cats.unshift('Todos')
                }
                setCategorias(cats)
            }
        })
    }, [cData])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: sCell ? 1 : 6}}
        >


            <Grid item lg={4} sm={12} xs={12} sx={{px: sCell ? 3 : 0, marginTop: sCell ? 3 : 0}}>
                <Typography sx={{color: "#fff", fontWeight: 900, fontSize: 40}}>eBooks</Typography>
            </Grid>

            <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >

                    <Tabs
                        variant="scrollable"
                        allowScrollButtonsMobile
                        scrollButtons
                        value={escogida}
                        onChange={(e, n) => setEscogida(n)}
                    >
                        {categorias.map((item, index) => {
                            return (
                                <Tab
                                    key={index}
                                    value={item}
                                    label={item}/>
                            )
                        })}
                    </Tabs>


                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: "center", px: sTab ? 0 : 6, marginTop: 6}}>
                <ListaLibros categoria={escogida}/>
            </Grid>


        </Grid>
    )
}

export default Catalogo;

const libros = [1, 2, 3, 4, 5]