/**************************************************
 * Nombre:       SobreEbook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useContext, useEffect, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import fondo from "../../Recursos/fondomain.svg";
import {theme} from "../../Tema";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import libro from '../../Recursos/libro.png'
import InformacionDescarga from "./SubComponentes/InformacionDescarga";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {usuarioContext} from "../../App";


const SobreEbook = ({ebook}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const cData = useContext(usuarioContext)

    return (
        <Grid
            container
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                maxWidth: "1400px",
                px: 3,
                backgroundImage: `url(${fondo})`,
                minHeight: masSM ? "92.3vh" : "95vh",
                backgroundSize: "cover",
                paddingTop: 14
            }}
        >

            <Grid item container lg={5} sm={6} xs={12} sx={{justifyContent: "center", marginTop: -4}}>
                <img src={cData.ingles ? ebook.imgEng : ebook.img} width={"58%"} height={"auto"} alt={"Ebook"}
                     loading={'eager'}
                     style={{transform: 'rotate(-14deg)', boxShadow: '0px 10px 30px #00000050'}}/>
            </Grid>

            <Grid item lg={1} sm={1} xs={12}/>

            <Grid item lg={5} sm={5} xs={12} sx={{marginTop: sCell ? 4 : 0}}>
                <InformacionDescarga item={ebook}/>
            </Grid>


        </Grid>
    )
}

export default SobreEbook;