/**************************************************
 * Nombre:       BarraMenus
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";


const BarraMenus = ({secciones}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            {secciones.map((item, index) => {
                return (
                    <Grid item key={index} container lg={3} sm={4} xs={12} sx={{justifyContent: "center"}}>
                        <ButtonBase
                            href={'#'+item}
                        >
                            <Typography sx={{
                                color: item === "Jenny Lesmes" ? "#fff" : "#253136",
                                fontSize: 18,
                                fontWeight: 900
                            }}>{item}</Typography>
                        </ButtonBase>
                    </Grid>
                )
            })}


        </Grid>
    )
}

export default BarraMenus;