export const iniciarChatWhatsApp = ({num, nombre = "", tema = " "}) => {


    const text = `https://api.whatsapp.com/send?phone=1${num}&text=Hola%20${procesarTexto(nombre)}necesito%20mas%20información%20${procesarTexto(tema)}`

    window.open(text)

}
export const iniciarChatWhatsAppIndicador = ({indicativo, num, nombre = "", tema = " "}) => {

    let ind = indicativo.substr(1, indicativo.length)

    const text = `https://api.whatsapp.com/send?phone=${ind}${num}&text=Hola%20${procesarTexto(nombre)}%20${procesarTexto(tema)}`

    window.open(text)

}

const procesarTexto = (text) => {

    if (text === "") {
        return text
    } else {
        try {
            let noEspacios = text.replaceAll(" ", "%20")
            return noEspacios
        } catch (e) {
            alert("Esta funcion no esta disponible en esta version de tu navegador")
        }

    }


}