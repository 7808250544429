/**************************************************
 * Nombre:       Dialogo_Descargar
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo_redondo.png'
import {irUrl} from "../../Utilidades/irUrl";
import {theme} from "../../Tema";


const Dialogo_Descargar = ({id}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [eBook, setEbook] = useState({})


    useEffect(() => {
        console.log(id)
        if (id) {
            setEbook(id)
        } else {
            setEbook({})
        }
    }, [id])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <img src={logo} width={150} height={'auto'} alt={'logo construyendome'} title={'logo'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <Typography sx={{fontWeight: 900, fontSize: 40, textAlign: 'center', lineHeight: 1}}>Gracias por tu
                    apoyo</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <Typography sx={{fontWeight: 500, fontSize: 18, textAlign: 'center', lineHeight: 1}}>Por favor descarga
                    el eBook haciendo click en el boton descargar, si tienes alguna dificultad para hacerlo no dudes en
                    escribirnos al correo info@construyendome.com</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <Typography sx={{fontWeight: 900, fontSize: 26, textAlign: 'center', lineHeight: 1}}>
                    Continua con la descarga, escoge el tipo de formato para descargar
                </Typography>
            </Grid>

            <Grid item container lg={6} sm={6} xs={12}
                  sx={{justifyContent: "center", marginTop: sCell ? 8 : 4, marginBottom: 2}}>
                <Button
                    onClick={() => irUrl(eBook.archivoPDF)}
                >Descargar PDF</Button>
            </Grid>

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: "center", marginTop: 4, marginBottom: 2}}>
                <Button
                    onClick={() => irUrl(eBook.archivo)}
                >Descargar EPUB</Button>
            </Grid>


        </Grid>
    )
}


export default Dialogo_Descargar;