import {useEffect, useState} from 'react'
import {Dialog} from "@mui/material";

const key = "c1601a4e8b4c43552b840d7167759c40"

export const useBotonPagar = ({datos, adaptador}) => {
    const [open, setOpen] = useState(false)
    const [obj, setObj] = useState({})

    const abrir = () => {
        setOpen(true)

    }

    const cerrar = () => {
        setOpen(false)
    }

    const Dialogo = () => {

        useEffect(() => {
            const script = document.createElement("script");
            script.src = "https://checkout.epayco.co/checkout.js";
            script.async = true;

            document.body.appendChild(script);

            return () => {

                document.body.removeChild(script);
            };

        }, []);

        useEffect(() => {
            if (adaptador) {
                setObj(adaptador(datos))
            } else {
                setObj(datos)
            }
        }, [datos])

        return (
            <Dialog open={open} onClose={cerrar}>
                <div style={{display: "none"}}>
                    <form>
                        <script
                            src="https://checkout.epayco.co/checkout.js"
                            className="epayco-button"
                            data-epayco-key={key}
                            data-epayco-amount={5}
                            data-epayco-name={obj.nombre}
                            data-epayco-description={obj.nombre}
                            data-epayco-currency="usd"
                            data-epayco-country="co"
                            data-epayco-lang={"es"}
                            data-epayco-extra1={obj.id}
                            data-epayco-extra2={obj.archivo}
                            // data-epayco-extra3={"getFechaCompleto(cita.startAt)"}
                            /// data-epayco-extra4={"getHora(cita.startAt)"}
                            data-epayco-test="false"
                            data-epayco-autoclick="true"
                            data-epayco-external="false"
                            data-epayco-response={
                                "http://localhost:3000/"
                            }


                        />
                    </form>
                </div>
            </Dialog>
        )

    }


    return ({
            Dialogo,
            abrir
        }
    )
}
