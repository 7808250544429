/**************************************************
 * Nombre:       JennyLesmes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useContext} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import jenny from '../../Recursos/jenny.png'
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";
import {LINKMAIL, LINKWASS} from "../../Constantes";
import {usuarioContext} from "../../App";
import {
    aplicaEng,
    aplicaEsp,
    buscadoraEng,
    buscadoraEsp,
    enel2020Eng,
    enel2020Esp, escribemeEng, escribemeEsp, inspiracionEng, inspiracionEsp,
    meDiCuentaEng,
    meDiCuentaEsp, siTieneEng, siTieneEsp, suVerdaderaEng, suVerdaderaEsp
} from "../../Textos/TextosJennyLesmes";
import {Whatsapp} from "iconsax-react";


const JennyLesmes = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const cData = useContext(usuarioContext)

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                component={'div'}
                id={'Jenny%20Lesmes'}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3, pt: masSM ? 14 : 8}}
            >

                <Grid item lg={11} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        spacing={masSM ? 0 : 2}
                    >


                        <Grid item lg={4} sm={6} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start" sx={{pb: 4}}
                            >

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{
                                        fontWeight: 900,
                                        fontSize: 36,
                                        color: "#FC783E",
                                        textAlign: sCell ? "center" : "left"
                                    }}>Jenny
                                        Lesmes</Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{
                                        fontWeight: 400,
                                        fontSize: 18,
                                        textAlign: sCell ? "center" : "left",
                                        marginTop: sCell ? 2 : 2
                                    }}>
                                        {cData.ingles ? buscadoraEng : buscadoraEsp}
                                    </Typography>

                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 3}}>
                                    <Typography sx={{
                                        fontWeight: 400,
                                        fontSize: 18,
                                        textAlign: sCell ? "center" : "left",
                                        marginTop: sCell ? 2 : 2
                                    }}>
                                        {cData.ingles ? enel2020Eng : enel2020Esp}
                                    </Typography>
                                </Grid>

                                <Grid item lg={1} sm={12} xs={12}>

                                </Grid>

                                <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 3, marginBottom: 2}}>
                                    <Typography sx={{
                                        fontWeight: 800,
                                        fontSize: 18,
                                        textAlign: sCell ? "center" : "left",
                                        marginTop: sCell ? 2 : 0
                                    }}>
                                        {cData.ingles ? meDiCuentaEng : meDiCuentaEsp}
                                    </Typography>
                                </Grid>


                            </Grid>
                        </Grid>

                        {masSM && <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: "center"}}>
                            <img src={jenny} width={masSM ? "100%" : '70%'} alt={"Jenny Lesmes"} height={"auto"}
                                 title={"Jenny Lesmes"}/>
                        </Grid>}

                        <Grid item container lg={4} sm={6} xs={12} sx={{justifyContent: "flex-end"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{
                                        fontWeight: 900,
                                        fontSize: 26,
                                        textAlign: sCell ? "center" : "left",
                                        marginTop: sCell ? 2 : 0
                                    }}>
                                        {cData.ingles ? inspiracionEng : inspiracionEsp}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{
                                        fontWeight: 400,
                                        fontSize: 18,
                                        textAlign: sCell ? "center" : "left",
                                        marginTop: sCell ? 2 : 0
                                    }}>
                                        {cData.ingles ? suVerdaderaEng : suVerdaderaEsp}
                                    </Typography>
                                </Grid>


                                <Grid item lg={1} sm={12} xs={12}>

                                </Grid>

                                <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 3}}>
                                    <Typography sx={{
                                        fontWeight: 400,
                                        fontSize: 18,
                                        textAlign: sCell ? "center" : "left",
                                        marginTop: sCell ? 2 : 0
                                    }}>
                                        {cData.ingles ? aplicaEng : aplicaEsp}
                                    </Typography>
                                </Grid>

                                <Grid item lg={2} sm={12} xs={12}>

                                </Grid>

                                <Grid container item lg={10} sm={12} xs={12}
                                      sx={{marginTop: 4, justifyContent: sCell ? "center" : "flex-start"}}>
                                    <Button
                                        endIcon={<Whatsapp variant={'Bold'}/>}
                                        onClick={() => irUrl(LINKWASS)}
                                        sx={{borderRadius: 2}}>{cData.ingles ? escribemeEng : escribemeEsp}</Button>
                                </Grid>

                                <Grid item lg={2} sm={12} xs={12}>

                                </Grid>

                                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 2, marginBottom: 4}}>
                                    <Typography sx={{
                                        fontWeight: 400,
                                        fontSize: 16,
                                        textAlign: sCell ? "center" : "left",
                                        marginTop: sCell ? 2 : 0
                                    }}>
                                        {cData.ingles ? siTieneEng : siTieneEsp}
                                    </Typography>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default JennyLesmes;