/**************************************************
 * Nombre:       HacercaEbook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useContext} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import jenny from '../../Recursos/jenny_lesmes.png'
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";
import {REDES} from "../../Constantes";
import {usuarioContext} from "../../App";


const HacercaEbook = ({ebook}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const cData = useContext(usuarioContext)

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: '#FEFFED'}}
        >

            <Grid
                component={'div'}
                id={cData.ingles ? 'About%20eBook' : 'Acerca%20del%20eBook'}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3, py: 6}}
            >

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: "center"}}>
                    <img
                        onClick={() => irUrl(REDES[1].url)}
                        src={jenny} width={"60%"} height={"auto"} alt={"Jenny Lesmes"} title={"Jenny Lesmes"}
                        style={{cursor: 'pointer'}}
                    />
                </Grid>

                <Grid item lg={5} sm={6} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: sCell ? 2 : 0}}>
                            <Typography sx={{fontSize: 34, fontWeight: 800, textAlign: 'right'}}>
                                <span>{cData.ingles ? "About" : 'Acerca de'}</span> <br/>
                                {cData.ingles ? ebook.nombreEng : ebook.nombre}</Typography>
                        </Grid>


                        <Grid container item lg={12} sm={12} xs={12}
                              sx={{marginTop: 1, justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Typography sx={{fontSize: 18, fontWeight: 500, textAlign: sCell ? 'center' : 'right'}}>
                                {ebook && cData.ingles ? ebook.largoEng : ebook.largo}
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 4 : 1}}>
                            <Typography sx={{fontSize: 24, fontWeight: 600, textAlign: sCell ? 'center' : 'right'}}>Jenny
                                Lesmes</Typography>
                        </Grid>

                        <Grid container item lg={12} sm={12} xs={12}
                              sx={{marginTop: 4, justifyContent: sCell ? 'center' : 'flex-end'}}>
                            <Button
                                href={'#Mis%20Redes%20Sociales'}
                                color={'secondary'}
                                sx={{borderRadius: 2}}>{cData.ingles ? 'My Social Media' : 'Mis Redes'}</Button>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default HacercaEbook;