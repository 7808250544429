/**************************************************
 * Nombre:       Formulario_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase, InputLista} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Layer} from "iconsax-react";


const Formulario_Categorias = (props) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >


                <Grid item lg={12} sm={12} xs={12} >
                    <FormBase.InputLista label={"Categorias"} Icono={Layer} dato={'categorias'}
                                         opciones={['Uno', 'Dos', 'Tres']}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Categorias;