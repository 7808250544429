import {useEntidadLocal} from "./useEntidadLocal";
import {useEffect} from "react";

export const useFormulario = ({Form, nombre, datosDefault = null, almacenarLocal = false}) => {
    const {entidad, cambioEntidad, limpiarEntidad, setEntidad} = useEntidadLocal({
        nombre: nombre,
        datosDefault,
        almacenarLocal: almacenarLocal
    })

    const setDato = (key, valor) => {
        setEntidad({...entidad, [key]: valor})
    }


    const props = {
        entidad: entidad,
        cambio: cambioEntidad,
        setDato: setDato
    }

    useEffect(() => {
        if (datosDefault){
            setEntidad(datosDefault)
        }

    }, [datosDefault])
    return {
        props,
        Formulario: Form,
        limpiarEntidad,
        entidad,
        setEntidad

    }
}