/**************************************************
 * Nombre:       Tarjeta_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Book, Buildings, Flag, Lock, Lovely, Message, Message2, Mobile, Moneys, Sms} from "iconsax-react";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";


const Tarjeta_Compradores = ({valores, click, difiere}) => {

    return (

        <CardBase valores={valores} sombra={0}>
            <ButtonBase onClick={() => click(valores)}
                        sx={{width: '100%', textAlign: 'left', borderRadius: 2, '&:hover': {boxShadow: 4}}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    sx={{backgroundColor: '#EBEBEB', borderRadius: 2, p: 2}}
                    spacing={1}
                >

                    <Grid item lg={3} sm={6} xs={12}>
                        <CardBase.FechaComponente dato1={'fecha'}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item lg={12} sm={12} xs={12}>
                                    <CardBase.IconTexto Icono={Book} dato1={"nombre"} peso={800}/>
                                </Grid>
                                <Grid item lg={12} sm={12} xs={12}>
                                    <CardBase.IconTexto Icono={Flag} dato1={"estado"} lineas={2}/>
                                </Grid>

                            </Grid>

                        </CardBase.FechaComponente>
                    </Grid>

                    <Grid item lg={4} sm={6} xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTexto Icono={Book} dato1={"ebook"} lineas={2}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTextoMoneda Icono={Moneys} dato1={"valor"} dato2={'pago'}/>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item lg={2} sm={6} xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTexto Icono={Lovely} dato1={"sexo"} lineas={2}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTexto Icono={Buildings} dato1={"pais"} lineas={2}/>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTexto Icono={Sms} dato1={"correo"} lineas={2}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTexto Icono={Mobile} dato1={'indicativo'} dato2={"celular"} lineas={2}/>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </ButtonBase>
        </CardBase>


    )
}

export default Tarjeta_Compradores;