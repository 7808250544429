export const adaptadorItem = (item) => {

    let it = {
        total: parseInt(item.valor),
        nombre: item.nombre,
        archivo:item.archivo
    }


    return it;
}