/**************************************************
 * Nombre:       MisRedes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useContext, useEffect, useState} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import face from '../../Recursos/facebook.png'
import {theme} from "../../Tema";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {irUrl} from "../../Utilidades/irUrl";
import {usuarioContext} from "../../App";
import {llegarEng, llegarEsp, misRedesEng, misRedesEsp} from "../../Textos/TextosMisRedes";


const MisRedes = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [redes, setRedes] = useState([])
    const cData = useContext(usuarioContext)

    useEffect(() => {
        obtenerCol('redes').then((dox) => {
            if (dox.res) {
                setRedes(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            sx={{marginTop: 4}}
            container
            direction="row-reverse"
            justifyContent="flex-end"
            alignItems="flex-start"
        >


            <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "flex-end", px: sCell ? 4 : 0}}>
                <Typography
                    sx={{textAlign: masSM ? "right" : "center", fontSize: 18, color: masSM ? "#7C8081" : "#000"}}>
                    {cData.ingles ? llegarEng : llegarEsp}
                </Typography>
            </Grid>

            <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 4 : 0}}>
                <Typography sx={{color: masSM ? "#FC783E" : "#FFF", fontSize: 30, fontWeight: 900}}>
                    {cData.ingles ? misRedesEng : misRedesEsp}
                </Typography>
            </Grid>

            <Grid item lg={4} sm={12} xs={12}/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 12}}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={masSM ? 8 : 4}
                    sx={{px: masSM ? 20 : 4}}
                >

                    {redes.map((item, index) => {
                        return (

                            <Grid key={index} item container lg={6} sm={6} xs={12} sx={{justifyContent: "center"}}>
                                <ButtonBase
                                    onClick={() => irUrl(item.link)}
                                    sx={{width: '100%', borderRadius: 6, textAlign: 'left'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        sx={{
                                            border: 1,
                                            borderColor: "#39329920",
                                            borderRadius: 6,
                                            backgroundColor: "#FFF",
                                            boxShadow: 2,
                                            transition: 'all .3s ease-in-out',
                                            '&:hover': {boxShadow: 8}
                                        }}
                                    >


                                        <Grid item container lg={3} sm={4} xs={4}
                                              sx={{justifyContent: "center", p: masSM ? 3 : 2}}>
                                            <img src={item.img} alt={item.nombre} width={"100%"} height={"auto"}
                                                 title={item.nombre}/>
                                        </Grid>

                                        <Grid item lg={9} sm={8} xs={8} sx={{py: masSM ? 3 : 2, paddingRight: 3}}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >

                                                <Grid item lg={12} sm={12} xs={12}>
                                                    <Typography sx={{
                                                        color: "#FC783E",
                                                        fontWeight: 800,
                                                        fontSize: masSM ? 24 : 20,
                                                        lineHeight: 1.2
                                                    }}>{item.nombre}</Typography>
                                                </Grid>

                                                <Grid item lg={12} sm={12} xs={12}>
                                                    <Typography sx={{fontWeight: 600, fontSize: 14}}>
                                                        {'@' + item.usuario}
                                                    </Typography>
                                                </Grid>


                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </ButtonBase>
                            </Grid>

                        )
                    })}


                </Grid>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 10}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >

                    <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "center", px: sCell ? 4 : 0}}>
                        <Typography sx={{textAlign: "center", fontSize: 18, color: masSM ? "#7C8081" : "#000",}}>
                            {cData.ingles ? llegarEng : llegarEsp}
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )
}

export default MisRedes;


