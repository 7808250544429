/**************************************************
 * Nombre:       InformacionDescarga
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useContext, useEffect, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Descarga_Gratis from "../../Formularios/Formulario_Descarga_Gratis";
import {theme} from "../../../Tema";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";
import {PAISES} from "../../../Constantes";
import {enviarMail} from "../../../Modulo_MailRelay/enviarMail";
import {emailfree} from "../../../Modulo_MailRelay/Recursos/emailfree";
import {emailcontruyendome} from "../../../Modulo_MailRelay/Recursos/emailcontruyendome";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {useBotonPagar} from "../../../Modulo_Epayco/Hooks/useBotonPagar";
import {adaptadorItem} from "../../../Modulo_Epayco/Adaptadores/adaptadorItem";
import {guardarPotencial} from "../../funciones/guardarPotencial";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Descargar from "../../Dialogos/Dialogo_Descargar";
import {usuarioContext} from "../../../App";
import {algoInesperadoEng, algoInesperadoEsp, datosCompeltosEsp, datosCompletosEng} from "../../../Textos/TexosAlertas";


const InformacionDescarga = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const cData = useContext(usuarioContext)
    const [datosCompra, setDatosCompra] = useState({})
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Descarga_Gratis,
        nombre: "gratis",
    })
    const [cargando, setCargando] = useState(false)
    const {abrir, Dialogo} = useBotonPagar({datos: datosCompra, adptador: adaptadorItem})
    const {Dialogo: Dialogo_Agradecimiento, abrir: abrir_Agradecimiento} = useDialogo({Componente: Dialogo_Descargar})


    const guardarCompra = () => {

        if (!entidad.nombre || !entidad.correo) {
            alert(cData.ingles ? datosCompletosEng : datosCompeltosEsp)
            return;
        }

        setCargando(true)
        guardarPotencial(entidad, item, false).then((dox) => {
            if (dox.res) {
                setDatosCompra({...datosCompra, id: dox.data})
                setTimeout(() => {
                    abrir()
                }, 800)

            } else {
                alert(cData.ingles ? algoInesperadoEng : algoInesperadoEsp)
            }
            setCargando(false)
        })
    }

    const guardar = () => {

        if (!entidad.nombre || !entidad.correo) {
            alert(cData.ingles ? datosCompletosEng : datosCompeltosEsp)
            return;
        }

        setCargando(true)
        guardarPotencial(entidad, item, true).then((dox) => {
            if (dox.res) {
                abrir_Agradecimiento(item)
            } else {
                alert(cData.ingles ? algoInesperadoEng : algoInesperadoEsp)
            }
            setCargando(false)

        })

    }

    useEffect(() => {
        setDatosCompra(item)
    }, [item])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Dialogo/>

            <Dialogo_Agradecimiento/>

            <Grid item lg={12} sm={12} xs={12}>
                <Typography sx={{color: "#FC783E", fontSize: masSM ? 30 : 28, fontWeight: 900}}>
                    {cData.ingles ? item.nombreEng : item.nombre}
                </Typography>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                <Typography
                    sx={{color: "#253136", fontSize: masSM ? 34 : 26, fontWeight: 600, lineHeight: 1}}>
                    {cData.ingles ? item.sloganEng : item.slogan}
                </Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                <Typography sx={{color: "#7C8081", fontSize: masSM ? 22 : 18, fontWeight: 500}}>
                    {cData.ingles ? item.resumenEng : item.resumen}
                </Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >

                    <Grid item lg={11} sm={12} xs={12}>
                        <Formulario {...props} ingles={cData.ingles}/>
                    </Grid>

                </Grid>

            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: masSM ? 3 : 8}}>
                {item.valor > 0 ?

                    <LoadingButton
                        loading={cargando}
                        variant={'contained'}
                        onClick={() => guardarCompra()}
                        sx={{px: 8, fontWeight: 800, py: 1}}
                    >{'Comprar ' + formatoMoneda(item.valor)}</LoadingButton>

                    :

                    <LoadingButton
                        loading={cargando}
                        variant={'contained'}
                        onClick={() => guardar()}
                        sx={{px: 8, fontWeight: 800, py: 1}}
                    >{cData.ingles ? 'Donwload' : 'Descargar'}</LoadingButton>

                }


            </Grid>


        </Grid>
    )
}

export default InformacionDescarga;