/**************************************************
 * Nombre:       Tarjeta_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Book, Category2, Lock, Message, Message2, User} from "iconsax-react";


const Tarjeta_Red = ({valores, click, difiere}) => {

    return (


        <CardBase valores={valores} sombra={0}>
            <ButtonBase onClick={() => click(valores)}
                        sx={{width: '100%', textAlign: 'left', borderRadius: 2, '&:hover': {boxShadow: 4}}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{backgroundColor: '#EBEBEB', borderRadius: 2, p: 2}}
                >


                    <Grid item lg={12} sm={12} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <Grid item lg={2} sm={2} xs={2}>
                                <img src={valores.img} width={'80%'} height={'auto'}/>
                            </Grid>

                            <Grid item lg={10} sm={10} xs={10}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item lg={12} sm={12} xs={12}>
                                        <CardBase.IconTexto Icono={Category2} dato1={"nombre"}/>
                                    </Grid>

                                    <Grid item lg={12} sm={12} xs={12}>
                                        <CardBase.IconTexto Icono={User} dato1={"usuario"}/>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </ButtonBase>
        </CardBase>


    )
}

export default Tarjeta_Red;