/**************************************************
 * Nombre:       Formulario_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Collapse, Grid} from "@mui/material";
import {FormBase, InputArchivo} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Book, Flag, Lovely, Message, Message2, Money} from "iconsax-react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {subirArchivo} from "../../Servicios/Storage/subirArchivo";


const Formulario_Ebook = (props) => {
    const [categorias, setCategorias] = useState([])
    const [categoriasEng, setCategoriasEng] = useState([])


    useEffect(() => {

        obtenerDoc('categorias', 'categorias').then((dox) => {
            if (dox.res && dox.data.categorias) {
                setCategorias(dox.data.categorias)
            }
        })

        obtenerDoc('categoriasEng', 'categoriasEng').then((dox) => {
            if (dox.res && dox.data.categorias) {
                setCategoriasEng(dox.data.categorias)
            }
        })

    }, [])
    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.ImagenDimencion dato={"img"} carpeta={"portadas"} y={3} x={2}
                                              funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                    <FormBase.InputIcon Icono={Book} label={'Nombre eBook'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={8}>
                    <FormBase.InputSelect Icono={Flag} label={'Categoria'} dato={'categoria'} opciones={categorias}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={4}>
                    <FormBase.InputIcon Icono={Money} label={'Valor'} dato={'valor'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Lovely} label={'Slogan'} dato={'slogan'} lineas={2}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message2} label={'Resumer corto'} dato={'resumen'} lineas={2}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message} label={'Mis palabras sobre el libro'} dato={'largo'}
                                        lineas={10}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Cargar Archivo ePub'} lg={6} sm={6} xs={6}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputArchivo label={'Cargar Archivo'} dato={'archivo'} funcion={subirArchivo}
                                           nombre={props.entidad.nombre || 'NA'}
                                           extencion={props.entidad.extencion || ''}
                                           carpeta={'epubs'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Cargar Archivo Pdf'} lg={6} sm={6} xs={6}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputArchivo label={'Cargar Archivo'} dato={'archivoPDF'} funcion={subirArchivo}
                                           nombre={props.entidad.nombre || 'NA'}
                                           extencion={props.entidad.extencion || ''}
                                           carpeta={'pdfs'}/>
                </Grid>


                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "center"}}>
                    <FormBase.DividerTexto texto={'Version en Ingles'}/>
                </Grid>

                {/*****Version en Ingles*****/}

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                    <FormBase.InputCheck label={'Mostrar Version'} dato={'ingles'}/>
                </Grid>

                <Collapse in={props.entidad.ingles} sx={{width: '100%', px: 2}}>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        sx={{marginTop: 4}}

                    >

                        <Grid item lg={3} sm={3} xs={3}>

                        </Grid>

                        <Grid item lg={6} sm={6} xs={6}>
                            <FormBase.ImagenDimencion dato={"imgEng"} carpeta={"portadas"} y={3} x={2}
                                                      funcion={subirUnaImagenCroper}/>
                        </Grid>

                        <Grid item lg={3} sm={3} xs={3}>

                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                            <FormBase.InputIcon Icono={Book} label={'Nombre eBook Ingles'} dato={'nombreEng'}/>
                        </Grid>

                        <Grid item lg={6} sm={6} xs={8}>
                            <FormBase.InputSelect Icono={Flag} label={'Categoria Ingles'} dato={'categoriaEng'}
                                                  opciones={categoriasEng}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <FormBase.InputIcon Icono={Lovely} label={'Slogan Ingles'} dato={'sloganEng'} lineas={2}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <FormBase.InputIcon Icono={Message2} label={'Resumer corto Ingles'} dato={'resumenEng'}
                                                lineas={2}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <FormBase.InputIcon Icono={Message} label={'Mis palabras sobre el libro Ingles'}
                                                dato={'largoEng'}
                                                lineas={10}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <FormBase.DividerTexto texto={'Cargar Archivo Epub'} lg={6} sm={6} xs={6}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <FormBase.InputArchivo label={'Cargar Archivo Eng'} dato={'archivoEng'}
                                                   funcion={subirArchivo}
                                                   nombre={props.entidad.nombre || 'NA'}
                                                   extencion={props.entidad.extencion || ''}
                                                   carpeta={'ebooks'}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <FormBase.DividerTexto texto={'Cargar Archivo Epub'} lg={6} sm={6} xs={6}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <FormBase.InputArchivo label={'Cargar Archivo Eng PDF'} dato={'archivoEngPDF'}
                                                   funcion={subirArchivo}
                                                   nombre={props.entidad.nombre || 'NA'}
                                                   extencion={props.entidad.extencion || ''}
                                                   carpeta={'ebooks'}/>
                        </Grid>


                    </Grid>
                </Collapse>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Ebook;