/*******************************************************
 * Nombre: Componentes Dashboard
 * Descripcion : Contiene el template de todos los
 *               componentes que van en un dahboard,
 * estos se conectan por medio de un DashboardBase y
 * un Barra
 *
 * Libreirias: Mui.com, iconsax-react, react-datepicker,
 *             react-router-dom
 * Autor: Luis Rosero
 * Tiempo: 3 hrs
 *******************************************************/
import {Avatar, ButtonBase, Drawer, Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {ArrowCircleLeft, ArrowCircleRight, ArrowLeft, Logout, LogoutCurve} from "iconsax-react";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {theme} from "../../Tema";

const PRIMARIO = "#2A2536"

export const dasboardContex = createContext();

export const DashboardBase = ({children, componentes = [], componentesID = []}) => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [open, setOpen] = useState(false)
    const [ancho, setAncho] = useState(230)

    const abrirDrawer = () => {
        setOpen(true)

    }

    const cerrarDrawer = () => {
        setOpen(!open)
    }

    const {Provider} = dasboardContex;

    const valoresProvider = {
        open,
        abrirDrawer,
        cerrarDrawer,
        masSM,
        ancho,
        componentes,
        componentesID


    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{backgroundColor: PRIMARIO}}

        >
            <Router>

                <Provider value={valoresProvider}>

                    {children}

                </Provider>

            </Router>

        </Grid>
    )
}

const CompoDefalut = ({item, url, Icono, iconoGrande}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const cData = useContext(dasboardContex);
    let tit = decodeURI(location.pathname);
    let t = tit.substr(1, tit.length);
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <ButtonBase onClick={() => navigate(url)} sx={{width: "100%"}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{boxShadow: t === item ? 8 : 0, py: 1, borderRadius: 2, transition: 'opacity 2.0s ease-in',}}>


                <Grid item lg={cData.open ? 3 : 12} sm={3} xs={2} sx={{transition: 'opacity 2.0s ease-in',}}>
                    {cData.open ? Icono : iconoGrande}
                </Grid>

                {cData.open &&
                <Grid item lg={9} sm={4} xs={10}>
                    <Typography
                        sx={{color: "#fff", fontWeight: 600, fontSize: 18, textAlign: "left"}}>{item}</Typography>
                </Grid>
                }


            </Grid>

        </ButtonBase>
    )
}

export const MenuDrawer = ({
                               Componente = CompoDefalut,
                               credenciales = [],
                               color = PRIMARIO,
                               usuario = null,
                               fondo = null,
                               funSalir = null
                           }) => {
    const cData = useContext(dasboardContex);
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Drawer
            variant={cData.masSM ? 'permanent' : 'temporary'}
            anchor="left"
            open={cData.open}
            onClose={() => cData.cerrarDrawer()}
            color="inherit"
            PaperProps={{
                style: {
                    width: cData.open ? cData.ancho : 100,
                    border: 'none',
                    zIndex: 100,
                    backgroundColor: color,
                    transition: masSM ? 'all .5s ease-in-out' : "none",
                },
            }}
            sx={{zIndex: masSM ? 10 : 200}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                    p: 1.5,
                    transition: masSM ? 'all .5s ease-in-out' : "none",
                }}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 2}}>
                    <Grid item lg={cData.open ? 6 : 12} sm={12} xs={6} sx={{transition: 'all .5s ease-in-out',}}>
                        <img src={fondo} width={cData.open ? 70 : "100%"}/>
                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{
                    justifyContent: "center",
                    transition: 'all .5s ease-in-out',
                    marginTop: cData.open ? -6 : -9
                }}>
                    <Avatar src={usuario && usuario.img ? usuario.img : ''}
                            sx={{
                                height: cData.open ? 80 : 60,
                                width: cData.open ? 80 : 60,
                                transition: 'all .5s ease-in-out',

                            }}/>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{
                    justifyContent: "center",
                    marginTop: cData.open ? 2 : 0,
                    transition: 'all .5s ease-in-out',
                }}>
                    <Typography sx={{
                        color: "#fff",
                        fontWeight: 600,
                        transition: 'all .5s ease-in-out',
                    }}>{cData.open && usuario && usuario.preferencia ? usuario.preferencia : ""}</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: cData.open ? "flex-end" : "center", marginBottom: 1}}>
                    <IconButton onClick={() => cData.cerrarDrawer()}>
                        {cData.open ?
                            <ArrowCircleLeft color={"#fff"} variant={"Bold"} size={"2rem"}/> :
                            <ArrowCircleRight color={"#fff"} variant={"Bold"} size={"2rem"}/>}
                    </IconButton>

                </Grid>


                {credenciales.map((item, index) => {
                    let url = encodeURI(item)
                    let compo = cData.componentes.find(e => e.credencial === item)
                    return (
                        <Grid item lg={12} sm={12} xs={12} key={index} sx={{marginTop: 1}}>
                            <Componente item={item} url={"/" + url} Icono={compo && compo.icono}
                                        iconoGrande={compo && compo.iconoGrande}
                            />
                        </Grid>
                    )
                })}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{marginTop: 4}}
                >

                    <ButtonBase
                        onClick={() => funSalir()}
                        sx={{width: "100%"}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <Grid item container lg={cData.open ? 6 : 12} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginLeft: -0.4}}>
                                <LogoutCurve color={"#fff"}/>
                            </Grid>

                            <Grid item container lg={cData.open ? 1 : 12} sm={12} xs={12}
                                  sx={{justifyContent: "center"}}>
                                <Typography sx={{
                                    color: "#fff",
                                    fontSize: cData.open ? 14 : 11,
                                    textAlign: "left"
                                }}>SALIR</Typography>
                            </Grid>

                        </Grid>

                    </ButtonBase>

                </Grid>


            </Grid>


        </Drawer>
    )
}

export const Main = () => {
    const cData = useContext(dasboardContex);
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <main
            style={{
                zIndex: 20,
                width: "100%",
                minHeight: masSM ? "100vh" : "100vh",
                paddingTop: cData.masSM ? 0 : 0,
                paddingBottom: cData.masSM ? 0 : 0,
                paddingLeft: cData.masSM ? 0 : 0,
                paddingRight: cData.masSM ? 0 : 0,
                borderBottomLeftRadius: cData.masSM ? 40 : 0,
                borderTopLeftRadius: cData.masSM ? 40 : 0,
                marginLeft: cData.open && cData.masSM ? cData.ancho : cData.masSM ? 100 : 0,
                backgroundColor: "#fff",
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: 500,
                }),
                boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
            }}
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{minHeight: "90vh", width: "100%"}}
            >


                <Routes>


                    {cData.componentes.map((item, index) => {
                        let url = encodeURI(item.credencial)

                        return (
                            <Route path={"/" + url} element={item.seccion} key={index}> </Route>
                        )
                    })}


                    {cData.componentesID.map((item, index) => {

                        return (
                            <Route path={item.path} element={item.seccion} key={index}> </Route>
                        )
                    })}

                </Routes>


            </Grid>

        </main>
    )
}

//////////////////Barra//////////////////////
export const Barra = ({children}) => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <>
            {!masSM &&
            <BarraBase>
                {children}
            </BarraBase>
            }
        </>
    )
}

const BarraBase = ({children, color = PRIMARIO}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                backgroundColor: color, height: "70px", width: "100%", px: 2,
                position: "sticky",
                zIndex: 200,
                top: -1
            }}
        >
            {children}
        </Grid>
    )
}

export const Titulo = ({color = "#fff"}) => {
    const titulo = useLocation()

    let tit = decodeURI(titulo.pathname);
    let t = tit.substr(1, tit.length);

    let texto = ""
    for (let i = 0; i < t.length; i++) {
        if (t[i] === "/") {
            i = t.length
        } else {
            texto += t[i]
        }
    }

    return (
        <Typography sx={{color: color, fontWeight: 500, fontSize: 18}}>{texto}</Typography>
    )
}

export const OpenDrawer = ({children}) => {
    const cData = useContext(dasboardContex);

    return (
        <IconButton onClick={() => cData.abrirDrawer()}>
            {children}
        </IconButton>
    )
}

export const Salir = ({children, click}) => {

    return (
        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
            <IconButton onClick={() => click()}>
                {children}
            </IconButton>
        </Grid>

    )
}


DashboardBase.Barra = Barra
Barra.Titulo = Titulo
Barra.Salir = Salir
Barra.OpenDrawer = OpenDrawer
DashboardBase.Drawer = MenuDrawer
DashboardBase.Main = Main;

