/**************************************************
 * Nombre:       Formulario_Descarga_Gratis
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Grid} from "@mui/material";
import {FormBase, Input, InputIcon, InputSelect} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {GENEROS, obtenerPaises} from "../../Constantes";


const Formulario_Descarga_Gratis = (props) => {
    const [paises, setPaises] = useState([])


    useEffect(() => {
        setPaises(obtenerPaises())

    }, [])
    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.Input label={props.ingles ? "Name" : "Nombre"} dato={"nombre"}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.InputSelect label={props.ingles ? "How do you identify?" : "Como te identificas"}
                                          dato={'sexo'} opciones={GENEROS}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.Input label={props.ingles ? "Email" : 'Correo'} dato={'correo'}/>
                </Grid>

                <Grid item lg={6} sm={4} xs={4}>
                    <FormBase.InputSelect label={props.ingles ? "Country" : 'Pais'} dato={'pais'} opciones={paises}/>
                </Grid>

                <Grid item lg={6} sm={8} xs={8}>
                    <FormBase.Input label={"WhatsApp"} dato={'celular'}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Descarga_Gratis;