/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useContext, useEffect, useState} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../Recursos/logocompletoblanco.svg'
import {theme} from "../Tema";
import {useDialogo} from "../Modulo_Dialgos/Hooks/useDialog";
import Login from "./Login/Login";
import {REDES} from "../Constantes";
import {irUrl} from "../Utilidades/irUrl";
import {usuarioContext} from "../App";
import {linksRapidosEng, linksRapidosEsp, seTrataEng, seTrataEsp} from "../Textos/TextosFooter";
import {seccionesEng, seccionesEsp} from "../Textos/TextosBarra";


const Footer = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Dialogo, abrir} = useDialogo({Componente: Login})
    const cData = useContext(usuarioContext)
    const [secc, setSecc] = useState([])


    useEffect(() => {
        if (cData.ingles) {
            setSecc(seccionesEng)
        } else {
            setSecc(seccionesEsp)
        }
    }, [cData])
    return (
        <>

            <Dialogo/>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{backgroundColor: "#2A2536"}}
            >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{maxWidth: "1400px", px: 4, py: 6}}
                >

                    <Grid item lg={2} sm={4} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <Typography sx={{color: "#fff", fontWeight: 800, fontSize: 22}}>
                                    {cData.ingles ? linksRapidosEng : linksRapidosEsp}
                                </Typography>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    {secc.map((item, index) => {
                                        return (
                                            <Grid key={index} item lg={12} sm={12} xs={12}>
                                                <ButtonBase
                                                    href={'#' + item}
                                                    sx={{px: 0}}>
                                                    <Typography sx={{color: "#fff"}}>{item} </Typography>
                                                </ButtonBase>
                                            </Grid>
                                        )
                                    })}

                                    <Grid item lg={12} sm={12} xs={12}>
                                        <ButtonBase
                                            onClick={() => abrir()}
                                            sx={{px: 0}}>
                                            <Typography
                                                sx={{color: "#fff"}}>
                                                {cData.ingles ? 'Administration' : 'Administracion'}</Typography>
                                        </ButtonBase>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item lg={5} sm={8} xs={12} sx={{marginTop: sCell ? 4 : 0}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <Typography sx={{color: "#fff", fontWeight: 800, fontSize: 22}}>
                                    {cData.ingles ? "About Construyendome" : 'Acerca de Construyendome'}
                                </Typography>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>

                                <Typography sx={{color: "#fff", fontSize: 16}}>
                                    {cData.ingles ? seTrataEng : seTrataEsp}
                                </Typography>

                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>

                                <Typography sx={{color: "#fff", fontSize: 16}}>
                                    <span style={{fontWeight: 800}}>{cData.ingles ? 'Email: ' : 'Correo: '}</span>
                                    info@construyendome.com
                                </Typography>

                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item container lg={5} sm={12} xs={12}
                          sx={{justifyContent: "center", marginTop: masSM ? 0 : 4}}>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                <img src={logo} width={sCell ? "80%" : "60%"} height={"auto"}
                                     alt={"Logo construyendome"}
                                     title={"Logo contruyendome"}/>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "flex-end", marginTop: sCell ? 4 : 1}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent={masSM ? "flex-end" : "center"}
                                    alignItems="flex-start"
                                    spacing={2}
                                >


                                    {REDES.map((item, index) => {
                                        return (
                                            <Grid key={index} item container lg={1} sm={1} xs={2}
                                                  sx={{justifyContent: "flex-end"}}>
                                                <ButtonBase
                                                    onClick={() => irUrl(item.url)}
                                                    sx={{borderRadius: 2}}>
                                                    <img src={item.img} width={sCell ? "80%" : "100%"} height={"auto"}
                                                         alt={"logo"}
                                                         title={"Logo"}/>
                                                </ButtonBase>
                                            </Grid>
                                        )
                                    })}


                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>


            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{backgroundColor: "#FC783E", p: 1}}
            >

                <Typography sx={{color: "#fff", fontSize: 16}}>Copyright © Construyéndome.com | 2023 - 2024</Typography>


            </Grid>


        </>

    )
}

export default Footer;



