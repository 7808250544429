/**************************************************
 * Nombre:       Compradores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Book, DocumentCloud} from "iconsax-react";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Ebook from "../Targetas/Tarjeta_Ebook";
import Tarjeta_Compradores from "../Targetas/Tarjeta_Compradores";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Detalle_Ebook from "../Dialogos/Detalle_Ebook";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import Detalle_Comprador from "../Dialogos/Detalle_Comprador";
import {exportarJsonToExcel} from "../../Modulo_Excel/Exportar/ExportarJsonToExcel";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import {LoadingButton} from "@mui/lab";


const Compradores = ({estado = 'nuevo'}) => {
    const {Dialogo, abrir} = useDialogo({Componente: Detalle_Comprador})
    const [wheres, setWheres] = useState([where('estado', '==', 'none')])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({col: 'potenciales', orden: 'fecha', wheres: wheres})
    const [cargando, setCargando] = useState(false)


    const exportar = () => {
        setCargando(true)
        let q = query(collection(fire, 'potenciales'), where('estado', '==', estado))
        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res) {
                exportarJsonToExcel({data: dox.data, nombre: 'Potenciales ' + estado})
            } else {
                alert('Ups, algo inesperado paso, por favor intentalo mas tarde')
            }
            setCargando(false)
        })


    }


    useEffect(() => {
        if (estado) {
            setWheres([where('estado', '==', estado)])
        }
    }, [estado])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={6} sm={3} xs={6}>
                        <Typography sx={{fontSize: 24, fontWeight: 800}}>{estado}</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={9} xs={6} sx={{justifyContent: "flex-end"}}>
                        <LoadingButton
                            onClick={() => exportar()}
                            loading={cargando} variant={'outlined'} sx={{color: "#FC783E"}}
                            startIcon={<DocumentCloud/>}>Descargar
                            Datos</LoadingButton>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas Card={Tarjeta_Compradores} data={data} click={abrir}/>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: 2, mb: 2}}>
                <Button
                    onClick={() => cargarMas()}
                    variant={'outlined'} sx={{color: '#FC783E'}}>Cargar Mas</Button>
            </Grid>

        </Grid>
    )
}

export default Compradores;