/**************************************************
 * Nombre:       Redes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Book, Category2} from "iconsax-react";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Ebook from "../Targetas/Tarjeta_Ebook";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Detalle_Ebook from "../Dialogos/Detalle_Ebook";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import Tarjeta_Red from "../Targetas/Tarjeta_Red";
import Detalle_Red from "../Dialogos/Detalle_Red";


const Redes = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Detalle_Red})
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({col: 'redes', orden: 'nombre', wheres: wheres})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={6} sm={6} xs={6}>
                        <Typography sx={{fontSize: 24, fontWeight: 800}}>Redes</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                        <Button
                            onClick={() => abrir()}
                            startIcon={<Category2 variant={'Bold'}/>}
                            sx={{borderRadius: 2}}>Nueva Red</Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas Card={Tarjeta_Red} data={data} click={abrir} lg={6}/>

                </Grid>
            </Grid>

        </Grid>
    )
}

export default Redes;