/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useContext, useEffect, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../Tema";
import Barra from "./Barra/Barra";
import SobreEbook from "./Componentes/SobreEbook";
import HacercaEbook from "./Componentes/HacercaEbook";
import fondo from '../Recursos/fondocatalogo.svg'
import Catalogo from "./Componentes/Catalogo";
import MisRedes from "./Componentes/MisRedes";
import JennyLesmes from "./Componentes/JennyLesmes";
import Footer from "./Footer";
import {obtenerDoc} from "../Servicios/BD/obtenerDoc";
import {useLocation} from 'react-router-dom'
import {IDDEFAULT} from "../Constantes";
import {useEscucharRecibo} from "../Modulo_Epayco/Hooks/useEscucharRecibo";
import Dialogo_Recibo from "../Modulo_Epayco/Dialogos/Dialogo_Recibo";
import {usuarioContext} from "../App";


const Pagina = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [ebook, setEbook] = useState({})
    const cData = useContext(usuarioContext)
    const location = useLocation()
    const {Dialogo} = useEscucharRecibo({Componente: Dialogo_Recibo})


    useEffect(() => {

        let id = IDDEFAULT

        if (location.pathname !== '/') {
            id = location.pathname.substr(1, location.pathname.length)
        }

        obtenerDoc('ebooks', id).then((doc) => {
            if (doc.res && doc.data) {
                setEbook(doc.data)
            } else {
                setEbook({})
                alert('Este link no lleva a ninguna parte, seras redirigido al eBook principal')
                obtenerDoc('ebooks', IDDEFAULT).then((doc) => {
                    if (doc.res) {
                        setEbook(doc.data)
                    } else {
                        setEbook({})
                    }
                })
            }
        })
    }, [location])

    useEffect(() => {
        if (ebook.ingles) {
            cData.setBoton(true)
        } else {
            cData.setBoton(false)
        }
    }, [ebook])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <h1 style={{display: 'none'}}>Estrategias para transformar tu vida</h1>
            <h2 style={{display: 'none'}} >Metodo construyendome</h2>

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 8}}>
                <Barra/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: -6, zIndex: 2}}>
                <SobreEbook ebook={ebook}/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <HacercaEbook ebook={ebook}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Grid
                    component={'div'}
                    id={cData.ingles ? 'More%20eBooks' : 'Mas%20eBooks'}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        maxWidth: "1400px",
                        px: sCell ? 0 : 3,
                        backgroundImage: `url(${fondo})`,
                        pb: 8,
                        backgroundSize: 'cover'
                    }}
                >


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <Catalogo/>
                    </Grid>


                    <Grid
                        component={'div'}
                        id={cData.ingles ? 'My%20Social%20Media' : 'Mis%20Redes%20Sociales'}
                        item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <MisRedes/>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{backgroundColor: '#FEFFED', backgroundSize: 'cover'}}>
                <JennyLesmes/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Footer/>
            </Grid>


        </Grid>
    )
}

export default Pagina;