import {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import {Component} from "iconsax-react";

export const useEscucharRecibo = ({Componente}) => {
    const [open, setOpen] = useState(false)
    const [datos,setDatos] = useState({})

    function getQueryVariable(variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    }

    const getDatos = (ref) => {

        fetch('https://secure.epayco.co/validation/v1/reference/' + ref)
            .then(async (response) => {
                const data = await response.json();

                //console.log(data.data);
                //  setDatosRecibo(data.data)
                // abrir()
                console.log(data)
                setDatos(data.data)
                abrir()

            })
            .catch((error) => {
                console.error('There was an error!', error);
            });

    };

    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }

    const Dialogo = () => {

        return (
            <Dialog open={open} fullWidth maxWidth={"xs"} onClose={cerrar}>

                <Componente item={datos}/>

            </Dialog>
        )
    }

    useEffect(() => {
        let ref = getQueryVariable('ref_payco');
        // console.log(ref)
        if (ref !== false) {
            getDatos(ref)
        }
    }, [])
    return ({
        Dialogo

    })

}